import { useCookies } from "react-cookie";
import "./cargaTabla.css";
import axios from "axios";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";
import { MultiSelect } from "react-multi-select-component";
import { useContext } from "react";
import { checkContext } from "../context/Checka";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ComponenteValoresContractuales from "../components/valoresContractuales/ValoresContractuales";
export default function TablaValoresContractuales() {
    const id = localStorage.getItem("id");
    const [cookies, setCookie] = useCookies(["user"]);
    const [denuncias, setDenuncias] = useState([]);
    const [codigos, setCodigos] = useState([])
    const [estadosNuevo, setEstadosNuevo] = useState([])
    const [opacity, setOpacity] = useState(0);
    const [isCalendar, setIsCalendar] = useState(0);
    const [ampSinLeer, setAmpSinLeer] = useState(null)
    let updatedList = denuncias;
    useEffect(() => {
        const fetchDataInStages = async () => {
            try {
                const estadosN = await axios.get(
                    "https://back.agsseguros.online/api/estados/estadosAll"
                );
                setEstadosNuevo(estadosN.data);
                const codigos = await axios.get(
                    "https://back.agsseguros.online/api/estados/codigoAll"
                );
                setCodigos(codigos.data);
                const denuncias = await axios.get("https://back.agsseguros.online/api/valoresContractuales/tablaValores3");
                setDenuncias(denuncias.data);
                const asignadoRes = await axios.get(
                    `https://back.agsseguros.online/api/asignadoCarga`,
                    { params: { asignado: cookies.user.usuario } }
                );
                setCookie("asignado", JSON.stringify(asignadoRes.data), { path: "/" });
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchDataInStages();
    }, [cookies.user.usuario, setCookie]);
    useEffect(() => {
        updatedList = denuncias;
    }, [denuncias]);
    // Date Calendar
    const [value, onChange] = useState(null);
    // finish calendar
    const [info, setInfo] = useState({
        poliza: "",
        nroStro: "",
        dominio: "",
        asignado: [],
        buscarGeneral2: "",
        estado: [],
        catStro: [],
        tipoStro: [],
        sicobe: [],
        prioridad: [],
        vencimiento: [],
    });
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    };
    const getTomorrowDate = () => {
        const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
        return tomorrow.toISOString().split("T")[0];
    };
    const todayDate = getTodayDate();
    const tomorrowDate = getTomorrowDate();
    const conFiltro = () => {
        if (info.buscarGeneral2) {
            updatedList = updatedList.filter(
                (d) =>
                    JSON.stringify(d.nroStro).includes(info.buscarGeneral2) ||
                    JSON.stringify(d.entryId).includes(info.buscarGeneral2) ||
                    JSON.stringify(d.pza).includes(info.buscarGeneral2) ||
                    JSON.stringify(d.dom).includes(info.buscarGeneral2) ||
                    (d.nombreAseg &&
                        JSON.stringify(d.nombreAseg)
                            .toLowerCase()
                            .includes(
                                JSON.stringify(info.buscarGeneral2)
                                    .toLowerCase()
                                    .replace(/[&\\#,+()$~%.'":*?<>{}]/g, "")
                            )) ||
                    (d.dominioTercero1 &&
                        JSON.stringify(d.dominioTercero1)
                            .toLowerCase()
                            .includes(
                                JSON.stringify(info.buscarGeneral2)
                                    .toLowerCase()
                                    .replace(/[&\\#,+()$~%.'":*?<>{}]/g, "")
                            )) ||
                    (d.propietarioTercero1 &&
                        JSON.stringify(d.propietarioTercero1)
                            .toLowerCase()
                            .includes(
                                JSON.stringify(info.buscarGeneral2)
                                    .toLowerCase()
                                    .replace(/[&\\#,+()$~%.'":*?<>{}]/g, "")
                            ))

            );
        }
        if (info.poliza) {
            updatedList = updatedList.filter((d) =>
                JSON.stringify(Number(d.pza)).includes(Number(info.poliza))
            );
        }
        if (info.nroStro) {
            updatedList = updatedList.filter((d) =>
                JSON.stringify(Number(d.nroStro)).includes(Number(info.nroStro))
            );
        }
        if (info.dominio) {
            updatedList = updatedList.filter((d) =>
                JSON.stringify(d.dom)
                    .replace(/[&\\#,+()$~%.'":*?<>{}]/g, "")
                    .toUpperCase()
                    .includes(info.dominio.toUpperCase())
            );
        }
        if (info.asignado.length > 0) {
            updatedList = updatedList.filter((d) => {
                return info.asignado.some((ele) => {
                    return ele.value === d.empleadoAsignado;
                });
            });
        }
        if (info.tipoStro.length > 0) {
            updatedList = updatedList.filter((d) => {
                return info.tipoStro.some((ele) => {
                    return ele.value === d.tipoStro;
                });
            });
        }
        if (info.estado.length > 0) {
            updatedList = updatedList.filter((d) => {
                return info.estado.some((ele) => {
                    return ele.value === d.estadoNuevo;
                });
            });
        }
        if (info.catStro.length > 0) {
            updatedList = updatedList.filter((d) => {
                if (!d.catStro) {
                    return false;
                }
                // Dividimos d.catStro en bloques de 3 dígitos y convertimos a cadenas
                const catStroValues = (d.catStro.match(/.{1,3}/g) || []).map((value) =>
                    value.toString()
                );
                // Mapeamos info.catStro para extraer los valores y convertimos a cadenas
                const infoValues = info.catStro.map((ele) => ele.value.toString());
                // Verificamos si todos los valores de infoValues están en catStroValues
                const allValuesPresent = infoValues.every((value) => catStroValues.includes(value));
                // const infoValues = info.catStro.filter(ele => ele && ele.value).map((ele) => ele.value.toString());
                //const atLeastOneValuePresent = infoValues.some((value) =>catStroValues.includes(value));
                return allValuesPresent;
                // return atLeastOneValuePresent;
            })
        }
        if (isCalendar == 0) {
            if (formatDate(value) !== "01/01/1970") {
                updatedList = updatedList.filter(
                    (d) => formatDate(d.fechaDenuncia) === formatDate(value)
                );
            } else if (formatDate(value) === "01/01/1970") {
            }
        } else if (isCalendar == 1) {
            if (formatDate(value) !== "01/01/1970") {
                updatedList = updatedList.filter(
                    (d) => formatDate(d.fechaVencimiento) === formatDate(value)
                );
            } else if (formatDate(value) === "01/01/1970") {
            }
        } else if (isCalendar == 2) {
            if (formatDate(value) !== "01/01/1970") {
                updatedList = updatedList.filter(
                    (d) => formatDate(d.recepcionRecChoques) === formatDate(value)
                );
            } else if (formatDate(value) === "01/01/1970") {
            }
        }
        if (info.sicobe.length > 0) {
            updatedList = updatedList.filter((d) => {
                return info.sicobe.some((ele) => {
                    return ele.value === d.cbtSicobe;
                });
            });
        }
        if (info.prioridad.length > 0) {
            updatedList = updatedList.filter((d) => {
                return info.prioridad.some((ele) => {
                    return ele.value === d.prioridad;
                });
            });
        }
        if (info.vencimiento.length > 0) {
            updatedList = updatedList.filter((d) => {
                const fechaVencimiento = new Date(d.fechaVencimiento);
                if (isNaN(fechaVencimiento)) {
                    return false;
                }
                const todayMatch =
                    info.vencimiento.some((filter) => filter.value === 1) &&
                    d.fechaVencimiento === todayDate;
                const tomorrowMatch =
                    info.vencimiento.some((filter) => filter.value === 2) &&
                    d.fechaVencimiento === tomorrowDate;
                const expiredMatch =
                    info.vencimiento.some((filter) => filter.value === 3) &&
                    fechaVencimiento.toISOString().split("T")[0] <
                    new Date().toISOString().split("T")[0];
                return todayMatch || tomorrowMatch || expiredMatch;
            });
        }
    };
    conFiltro();

    // FUNCION PARA ACTUALIZAR EL ASIGNADO
    const { checka, setChecka } = useContext(checkContext);
    function getCurrentTime() {
        const now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        return hour + ":" + minute;
    }
    const currentTime = getCurrentTime();
    const [selectedOption, setSelectedOption] = useState("");
    const optionsCarga = [
        { value: "JuanG", label: "JuanG", key: 1 },
        { value: "LucaP", label: "LucaP", key: 2 },
        { value: "StellaM", label: "StellaM", key: 3 },
        { value: "DanielaM", label: "DanielaM", key: 4 },
    ];
    const optionsChoque = [
        { value: "JuanG", label: "JuanG", key: 1 },
        { value: "LucaP", label: "LucaP", key: 2 },
        { value: "DanielaM", label: "DanielaM", key: 3 },
        { value: "DiegoD", label: "DiegoD", key: 4 },
        { value: "DiegoS", label: "DiegoS", key: 5 },
        { value: "OrelyC", label: "OrelyC", key: 6 },
        { value: "StellaM", label: "StellaM", key: 7 },
        { value: "LucianaF", label: "LucianaF", key: 8 },
        { value: "CandelaS", label: "CandelaS", key: 9 },
        { value: "MagaliG", label: "MagaliG", key: 10 },
        { value: "PilarS", label: "PilarS", key: 11 },
        { value: 'Maurom', label: 'Maurom', key: 12 },
        { value: 'ClaudiaF', label: 'ClaudiaF', key: 13 }
    ];
    const optionsContractuales = [
        { value: "JuanG", label: "JuanG", key: 1 },
        { value: "LucaP", label: "LucaP", key: 2 },
        { value: "DanielaM", label: "DanielaM", key: 3 },
        { value: "DiegoD", label: "DiegoD", key: 4 },
        { value: "DiegoS", label: "DiegoS", key: 5 },
        { value: "OrelyC", label: "OrelyC", key: 6 },
        { value: "StellaM", label: "StellaM", key: 7 },
        { value: "LucianaF", label: "LucianaF", key: 8 },
        { value: "CandelaS", label: "CandelaS", key: 9 },
        { value: "MagaliG", label: "MagaliG", key: 10 },
        { value: "PilarS", label: "PilarS", key: 11 },
        { value: "Augusto", label: "Augusto", key: 12 },
        { value: "PaulaC", label: "PaulaC", key: 13 },
    ];
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
    useEffect(() => {
        const handleKeyUp = (event) => {
            if (event.key === "Shift") {
                let checks = checka.slice(-2);
                const index1 = updatedList.findIndex((item) => item.id === checks[0]);
                const index2 = updatedList.findIndex((item) => item.id === checks[1]);
                for (let index = index1 + 1; index < index2; index++) {
                    const index3 = updatedList[index].id;
                    const a = checka.includes(index3);
                    if (!a) {
                        setChecka((prevChecka) => [...prevChecka, index3]);
                    }
                }
            }
        };
        document.addEventListener("keyup", handleKeyUp, setChecka(checka));
        return () => {
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, [checka, setChecka, updatedList]);
    const link = { textDecoration: "none" };
    // FILTRO PARA TIPO STRO
    const optionsTipoStro = [
        { label: "ACCIDENTE DE TRANSITO", value: "ACCIDENTE DE TRANSITO" },
        { label: "ROBO TOTAL", value: "ROBO TOTAL" },
        { label: "ROBO PARCIAL", value: "ROBO PARCIAL" },
        { label: "ROBO PARCIAL INSP", value: "ROBO PARCIAL INSP" },
        { label: "INCENDIO TOTAL", value: "INCENDIO TOTAL" },
        { label: "INCENDIO PARCIAL", value: "INCENDIO PARCIAL" },
        { label: "DAÑO TOTAL", value: "DAÑO TOTAL" },
        { label: "DAÑO PARCIAL", value: "DAÑO PARCIAL" },
    ];
    const opcionesResponsabilidad = codigos.map(item => ({
        label: item.nombre,
        value: item.codigo,
        tipoStro: item.tipoStro
    }));
    const estados = [
        { value: 1, label: "CARGADO", tipoStro: "GENERAL" },
        { value: 2, label: "CERRADO", tipoStro: "GENERAL" },

        { value: 101, label: "RR", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 102, label: "CTZ", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 103, label: "ASIG", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 110, label: "ACIA", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 104, label: "RCIA", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 105, label: "IN/REC", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 106, label: "TER", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 107, label: "CER", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 108, label: "ARCH", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 109, label: "LEGALES", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 111, label: "DOC EN OFICINA", tipoStro: "ACCIDENTE DE TRANSITO" },




        { value: 201, label: "EN PROCESO", tipoStro: "ROBO TOTAL" },
        { value: 202, label: "ESPERA INFORME INVESTIGACIÓN", tipoStro: "ROBO TOTAL", },
        { value: 203, label: "ESPERA DOCUMENTACIÓN PRODUCTOR", tipoStro: "ROBO TOTAL", },
        { value: 204, label: "ESPERA BAJA", tipoStro: "ROBO TOTAL" },
        { value: 205, label: "BAJA A GESTOR", tipoStro: "ROBO TOTAL" },
        { value: 206, label: "BAJA DEVUELTA POR GESTOR", tipoStro: "ROBO TOTAL" },
        { value: 207, label: "ESTADO BAJA", tipoStro: "ROBO TOTAL" },
        { value: 208, label: "EN PROCESO JJ", tipoStro: "ROBO TOTAL" },
        { value: 209, label: "EN LIQUIDACIÓN", tipoStro: "ROBO TOTAL" },
        { value: 210, label: "EN ESPERA ACEPTACIÓN", tipoStro: "ROBO TOTAL" },
        { value: 211, label: "TERMINADO DESCUENTO A MORÓN", tipoStro: "ROBO TOTAL", },
        { value: 212, label: "TERMINADO RECHAZADO", tipoStro: "ROBO TOTAL" },
        { value: 213, label: "LEGALES", tipoStro: "ROBO TOTAL" },

        { value: 301, label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR", tipoStro: "ROBO PARCIAL", },
        { value: 302, label: "ESPERA FACTURA PROVEEDOR", tipoStro: "ROBO PARCIAL" },
        { value: 303, label: "EN PROCESO", tipoStro: "ROBO PARCIAL" },
        { value: 310, label: "ARCHIVO", tipoStro: "ROBO PARCIAL" },
        { value: 304, label: "ARCHIVO ESPERA ACEPTACIÓN", tipoStro: "ROBO PARCIAL", },
        { value: 305, label: "ARCHIVO FACTURA PROVEEDOR", tipoStro: "ROBO PARCIAL", },
        { value: 306, label: "TERMINADO DESCUENTO A MORÓN", tipoStro: "ROBO PARCIAL", },
        { value: 307, label: "TERMINADO A TESORERÍA", tipoStro: "ROBO PARCIAL" },
        { value: 308, label: "TERMINADO RECHAZADO", tipoStro: "ROBO PARCIAL" },
        { value: 309, label: "LEGALES", tipoStro: "ROBO PARCIAL" },

        { value: 401, label: "EN ESPERA INFORME INSPECCIÓN", tipoStro: "ROBO PARCIAL INSP", },
        { value: 402, label: "EN ESPERA INFORME INVESTIGACIÓN", tipoStro: "ROBO PARCIAL INSP", },
        { value: 403, label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR", tipoStro: "ROBO PARCIAL INSP", },
        { value: 404, label: "ESPERA FACTURA PROVEEDOR", tipoStro: "ROBO PARCIAL INSP", },
        { value: 405, label: "EN PROCESO", tipoStro: "ROBO PARCIAL INSP" },
        { value: 412, label: "ARCHIVO", tipoStro: "ROBO PARCIAL INSP" },
        { value: 406, label: "ARCHIVO ESPERA ACEPTACIÓN", tipoStro: "ROBO PARCIAL INSP", },
        { value: 407, label: "ARCHIVO FACTURA PROVEEDOR", tipoStro: "ROBO PARCIAL INSP", },
        { value: 408, label: "TERMINADO DESCUENTO A MORÓN", tipoStro: "ROBO PARCIAL INSP", },
        { value: 409, label: "TERMINADO A TESORERÍA", tipoStro: "ROBO PARCIAL INSP", },
        { value: 410, label: "TERMINADO RECHAZADO", tipoStro: "ROBO PARCIAL INSP" },
        { value: 411, label: "LEGALES", tipoStro: "ROBO PARCIAL INSP" },

        { value: 501, label: "EN ESPERA INFORME INSPECCIÓN", tipoStro: "INCENDIO TOTAL", },
        { value: 502, label: "EN ESPERA INFORME INVESTIGACIÓN", tipoStro: "INCENDIO TOTAL", },
        { value: 503, label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR", tipoStro: "INCENDIO TOTAL", },
        { value: 504, label: "ESPERA FACTURA PROVEEDOR", tipoStro: "INCENDIO TOTAL", },
        { value: 505, label: "EN PROCESO", tipoStro: "INCENDIO TOTAL" },
        { value: 512, label: "ARCHIVO", tipoStro: "INCENDIO TOTAL" },
        { value: 506, label: "ARCHIVO ESPERA ACEPTACIÓN", tipoStro: "INCENDIO TOTAL", },
        { value: 507, label: "ARCHIVO FACTURA PROVEEDOR", tipoStro: "INCENDIO TOTAL", },
        { value: 508, label: "TERMINADO DESCUENTO A MORÓN", tipoStro: "INCENDIO TOTAL", },
        { value: 509, label: "TERMINADO A TESORERÍA", tipoStro: "INCENDIO TOTAL" },
        { value: 510, label: "TERMINADO RECHAZADO", tipoStro: "INCENDIO TOTAL" },
        { value: 511, label: "LEGALES", tipoStro: "INCENDIO TOTAL" },

        { value: 601, label: "EN ESPERA INFORME INSPECCIÓN", tipoStro: "INCENDIO PARCIAL", },
        { value: 602, label: "EN ESPERA INFORME INVESTIGACIÓN", tipoStro: "INCENDIO PARCIAL", },
        { value: 603, label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR", tipoStro: "INCENDIO PARCIAL", },
        { value: 604, label: "ESPERA FACTURA PROVEEDOR", tipoStro: "INCENDIO PARCIAL", },
        { value: 605, label: "EN PROCESO", tipoStro: "INCENDIO PARCIAL" },
        { value: 612, label: "ARCHIVO", tipoStro: "INCENDIO PARCIAL" },
        { value: 606, label: "ARCHIVO ESPERA ACEPTACIÓN", tipoStro: "INCENDIO PARCIAL", },
        { value: 607, label: "ARCHIVO FACTURA PROVEEDOR", tipoStro: "INCENDIO PARCIAL", },
        { value: 608, label: "TERMINADO DESCUENTO A MORÓN", tipoStro: "INCENDIO PARCIAL", },
        { value: 609, label: "TERMINADO A TESORERÍA", tipoStro: "INCENDIO PARCIAL", },
        { value: 610, label: "TERMINADO RECHAZADO", tipoStro: "INCENDIO PARCIAL" },
        { value: 611, label: "LEGALES", tipoStro: "INCENDIO PARCIAL" },

        { value: 701, label: "EN ESPERA INFORME INSPECCIÓN", tipoStro: "DAÑO TOTAL", },
        { value: 702, label: "EN ESPERA INFORME INVESTIGACIÓN", tipoStro: "DAÑO TOTAL", },
        { value: 703, label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR", tipoStro: "DAÑO TOTAL", },
        { value: 704, label: "ESPERA FACTURA PROVEEDOR", tipoStro: "DAÑO TOTAL" },
        { value: 705, label: "EN PROCESO", tipoStro: "DAÑO TOTAL" },
        { value: 712, label: "ARCHIVO", tipoStro: "DAÑO TOTAL" },
        { value: 706, label: "ARCHIVO ESPERA ACEPTACIÓN", tipoStro: "DAÑO TOTAL" },
        { value: 707, label: "ARCHIVO FACTURA PROVEEDOR", tipoStro: "DAÑO TOTAL" },
        { value: 708, label: "TERMINADO DESCUENTO A MORÓN", tipoStro: "DAÑO TOTAL", },
        { value: 709, label: "TERMINADO A TESORERÍA", tipoStro: "DAÑO TOTAL" },
        { value: 710, label: "TERMINADO RECHAZADO", tipoStro: "DAÑO TOTAL" },
        { value: 711, label: "LEGALES", tipoStro: "DAÑO TOTAL" },

        { value: 801, label: "EN ESPERA INFORME INSPECCIÓN", tipoStro: "DAÑO PARCIAL", },
        { value: 802, label: "EN ESPERA INFORME INVESTIGACIÓN", tipoStro: "DAÑO PARCIAL", },
        { value: 803, label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR", tipoStro: "DAÑO PARCIAL", },
        { value: 804, label: "ESPERA FACTURA PROVEEDOR", tipoStro: "DAÑO PARCIAL" },
        { value: 805, label: "EN PROCESO", tipoStro: "DAÑO PARCIAL" },
        { value: 812, label: "ARCHIVO", tipoStro: "DAÑO PARCIAL" },
        { value: 806, label: "ARCHIVO ESPERA ACEPTACIÓN", tipoStro: "DAÑO PARCIAL", },
        { value: 807, label: "ARCHIVO FACTURA PROVEEDOR", tipoStro: "DAÑO PARCIAL", },
        { value: 808, label: "TERMINADO DESCUENTO A MORÓN", tipoStro: "DAÑO PARCIAL", },
        { value: 809, label: "TERMINADO A TESORERÍA", tipoStro: "DAÑO PARCIAL" },
        { value: 810, label: "TERMINADO RECHAZADO", tipoStro: "DAÑO PARCIAL" },
        { value: 811, label: "LEGALES", tipoStro: "DAÑO PARCIAL" },
    ];
    const sicobeOptions = [
        { value: 1, label: "COBERTURA", key: 1 },
        { value: 2, label: "EN REVISION", key: 2 },
        { value: 3, label: "COBERTURA ANALIZADO POR COBRANZAS", key: 3 },
        { value: 4, label: "SIN COBERTURA ANALIZADO POR COBRANZAS", key: 4 },
        { value: 5, label: "PARA IMPUTAR", key: 5 },
    ];
    const actualizarAsignado = useCallback(
        async (e) => {
            e.preventDefault();
            setOpacity(1);
            await axios.post(`http://89.116.167.241:8080/api/actualizarAsignadoTabla`, {
                stro: checka,
                asignado: selectedOption,
                user: cookies.user.usuario,
                desc: `El usuario ${cookies.user.usuario} ha asignado el stro a ${selectedOption}`,
                obs: "",
                jerarquia: cookies.user.depto === "cargas" ? "CRG" : "DEN",
                motivo: "STRO",
                autor: cookies.user.usuario,
                fecha: formatDate(new Date()),
                hora: currentTime,
                mailAsignado: cookies.user.email
            }).then(async (response) => {
                await axios.post(`http://89.116.167.241:8080/api/mail/mailAsignadoTabla`, {
                    stro: checka,
                    user: selectedOption,
                }).then((res) => {
                    window.location.reload()
                })
            });
        },
        [selectedOption, checka, cookies, currentTime]
    );
    let itemsPerPage = 200;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = updatedList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(updatedList.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % updatedList.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    };
    const optionsPrioridad = [
        { label: "BAJA", value: 1 },
        { label: "MEDIA", value: 2 },
        { label: "ALTA", value: 3 },
    ];
    const optionsVencimiento = [
        { label: "HOY", value: 1 },
        { label: "MAÑANA", value: 2 },
        { label: "VENCIDOS", value: 3 },
    ];
    const optionsCalendar = [
        { label: "Filtro Fecha Recepcion", value: 0 },
        { label: "Filtro Fecha Vencimiento", value: 1 },
        { label: "Filtro Fecha Reclamo Recibido", value: 2 },
    ];
    const handleChange1 = (event) => {
        setIsCalendar(event.target.value);
    };
    const [sortA, setSortA] = useState(0);
    const sort = () => {
        if (sortA === 0) {
            updatedList.sort((a, b) => b.id - a.id);
        }
        if (sortA === 1) {
            updatedList.sort(function (a, b) {
                return b.nroStro - a.nroStro;
            });
        }
        if (sortA === 2) {
            updatedList.sort(function (a, b) {
                if (a.nroStro === null && b.nroStro === null) return 0;
                if (a.nroStro === null) return 1;
                if (b.nroStro === null) return -1;
                return a.nroStro - b.nroStro;
            });
        }
        if (sortA === 3) {
            updatedList.sort(function (a, b) {
                return b.entryId - a.entryId;
            });
        }
        if (sortA === 4) {
            updatedList.sort(function (a, b) {
                if (a.entryId === null && b.entryId === null) return 0;
                if (a.entryId === null) return 1;
                if (b.entryId === null) return -1;
                return a.entryId - b.entryId;
            });
        }
        if (sortA === 5) {
            updatedList.sort(function (a, b) {
                return b.pza - a.pza;
            });
        }
        if (sortA === 6) {
            updatedList.sort(function (a, b) {
                if (a.pza === null && b.pza === null) return 0;
                if (a.pza === null) return 1;
                if (b.pza === null) return -1;
                return a.pza - b.pza;
            });
        }
        if (sortA === 7) {
            updatedList.sort(function (a, b) {
                {
                    if (a.dom && b.dom)
                        return JSON.stringify(a.dom).localeCompare(JSON.stringify(b.dom));
                }
            });
        }
        if (sortA === 8) {
            updatedList.sort(function (a, b) {
                {
                    if (a.dom && b.dom)
                        return JSON.stringify(b.dom).localeCompare(JSON.stringify(a.dom));
                }
            });
        }
        if (sortA === 9) {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaDenuncia) - new Date(a.fechaDenuncia);
            });
        }
        if (sortA === 10) {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaDenuncia) - new Date(b.fechaDenuncia);
            });
        }
        if (sortA === 11) {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaStro) - new Date(a.fechaStro);
            });
        }
        if (sortA === 12) {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaStro) - new Date(b.fechaStro);
            });
        }
        if (sortA === 13) {
            updatedList.sort(function (a, b) {
                const dateA = isNaN(Date.parse(a.recepcionRecChoques))
                    ? null
                    : new Date(a.recepcionRecChoques);
                const dateB = isNaN(Date.parse(b.recepcionRecChoques))
                    ? null
                    : new Date(b.recepcionRecChoques);
                if (dateA === null && dateB === null) return 0;
                if (dateA === null) return 1;
                if (dateB === null) return -1;
                return dateB - dateA;
            });
        }
        if (sortA === 14) {
            updatedList.sort(function (a, b) {
                const isDateValid = (value) => {
                    const regex = /^\d{4}-\d{2}-\d{2}$/;
                    if (!regex.test(value)) return false;
                    const date = new Date(value);
                    return (
                        !isNaN(date.getTime()) && date.toISOString().slice(0, 10) === value
                    );
                };

                const parseDate = (value) => {
                    return isDateValid(value) ? new Date(value) : null;
                };

                const minDate = new Date("2024-08-01");
                const dateA = parseDate(a.recepcionRecChoques);
                const dateB = parseDate(b.recepcionRecChoques);

                const validDateA = dateA && dateA >= minDate ? dateA : null;
                const validDateB = dateB && dateB >= minDate ? dateB : null;

                if (validDateA === null && validDateB === null) return b.id - a.id;
                if (validDateA === null) return 1;
                if (validDateB === null) return -1;

                const dateComparison = validDateA - validDateB;
                if (dateComparison !== 0) return dateComparison;

                return b.id - a.id; // Ordena por id en orden descendente si las fechas son iguales
            });
        }
        if (sortA === 15) {
            updatedList.sort(function (a, b) {
                const dateA = isNaN(Date.parse(a.fechaVencimiento))
                    ? null
                    : new Date(a.fechaVencimiento);
                const dateB = isNaN(Date.parse(b.fechaVencimiento))
                    ? null
                    : new Date(b.fechaVencimiento);
                if (dateA === null && dateB === null) return 0;
                if (dateA === null) return 1;
                if (dateB === null) return -1;
                return dateB - dateA;
            });
        }
        if (sortA === 16) {
            updatedList.sort(function (a, b) {
                const isDateValid = (value) => {
                    const regex = /^\d{4}-\d{2}-\d{2}$/;
                    if (!regex.test(value)) return false;
                    const date = new Date(value);
                    return (
                        !isNaN(date.getTime()) && date.toISOString().slice(0, 10) === value
                    );
                };
                const parseDate = (value) => {
                    return isDateValid(value) ? new Date(value) : null;
                };
                const dateA = parseDate(a.fechaVencimiento);
                const dateB = parseDate(b.fechaVencimiento);
                if (dateA === null && dateB === null) return 0;
                if (dateA === null) return 1;
                if (dateB === null) return -1;
                return dateA - dateB;
            });
        }
        if (sortA === 17) {
            updatedList.sort(function (a, b) {
                return b.prioridad - a.prioridad;
            });
        }
        if (sortA === 18) {
            updatedList.sort(function (a, b) {
                if (a.prioridad === null && b.prioridad === null) return 0;
                if (a.prioridad === null) return 1;
                if (b.prioridad === null) return -1;
                if (a.prioridad === 0 && b.prioridad === 0) return 0;
                if (a.prioridad === 0) return 1;
                if (b.prioridad === 0) return -1;
                return a.prioridad - b.prioridad;
            });
        }
        if (sortA === 19) {
            updatedList.sort(function (a, b) {
                return b.estado - a.estado;
            });
        }
        if (sortA === 20) {
            updatedList.sort(function (a, b) {
                return a.estado - b.estado;
            });
        }
    };
    sort();
    const responsabilidadInput = [
        { label: "Cr", value: 320 },
        { label: "Cc", value: 320 },
        { label: "Sr", value: 300 },
        { label: "Rp", value: 400 },
        { label: "Rp1 (1 ruedas)", value: 401 },
        { label: "Rp2 (2 ruedas)", value: 402 },
        { label: "Rp3 (3 ruedas)", value: 403 },
        { label: "Rp4 (4 ruedas)", value: 404 },
        { label: "Rp5 (5 ruedas)", value: 405 },
        { label: "Rt", value: 500 },
        { label: "Ra", value: 550 },
        { label: "Raf", value: 551 },
        { label: "Ip", value: 600 },
        { label: "Dp", value: 800 },
        { label: "Dt", value: 900 },
        { label: "Aseg les", value: 100 },
        { label: "Aseg muerto", value: 130 },
        { label: "3ro les", value: 200 },
        { label: "3ro muerto", value: 230 },
        { label: "3ro muerto Rec", value: 233 },
    ];
    const responsabilidadChoques = [
        { label: "Cr", value: 320 },
        { label: "Cc", value: 320 },
        { label: "Sr", value: 300 },
        { label: "3ro les", value: 200 },
        { label: "3ro muerto", value: 230 },
        { label: "3ro muerto Rec", value: 233 },
    ];
    const responsabilidadRoboParcialInsp = [
        { label: "Rp1 (1 ruedas)", value: 401 },
        { label: "Rp2 (2 ruedas)", value: 402 },
        { label: "Rp3 (3 ruedas)", value: 403 },
        { label: "Rp4 (4 ruedas)", value: 404 },
        { label: "Rp5 (5 ruedas)", value: 405 },
        { label: "Ra", value: 550 },
        { label: "Raf", value: 551 },
    ];
    const responsabilidadRoboTotal = [{ label: "Rt", value: 500 }];
    const responsabilidadRoboParcial = [{ label: "Rp", value: 400 }];
    const responsabilidadDanioParcial = [{ label: "Dp", value: 800 }];
    const responsabilidadIncendioTotal = [];
    const responsabilidadIncendioParcial = [{ label: "Ip", value: 600 }];
    const responsabilidadDanioTotal = [{ label: "Dt", value: 900 }];

    const handleOpenInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const noFiltrosAplicados = () => {
        return (
            info.poliza === "" &&
            info.nroStro === "" &&
            info.dominio === "" &&
            info.asignado.length === 0 &&
            info.estado.length === 0 &&
            info.catStro.length === 0 &&
            info.tipoStro.length === 0 &&
            info.sicobe.length === 0 &&
            info.prioridad.length === 0 &&
            info.vencimiento.length === 0
        );
    };
    const sinFiltros = noFiltrosAplicados();

    const columns = [
        "NRO STRO", "NRO DEN", "PZA", "DOM", "F. RECEP", "F. STRO", "F. REC RECIB", "F. VTO", "PRIORIDAD",
        "ASIGNADO", "ESTADO", "TIPO STRO", "ausente", "contadorAusente", "videollamada", "fRecInsp", "docInfInv", "fRecInv",
        "nombreProveedor", "montoReconsideracion", "tipoArchivo", "fIngresoArchivo", "tipoTerminado", "fIngresoTerminado", "fRecBajaGestor", "recBajaGestor", "incompletaBaja",
        "fAsignadoPorJJ", "porResolucion", "porFechaPago", "observacion", "ausenteEII", "contadorAusenteEII", "videollamadaEII", "observacionEII ", "observacionEFP",
         "coordinadaEII", "fObservacionesDoc", "observacionesDoc", "fechaEsperaAceptacion", ""];

    return (
        <>
            {cookies.user?.depto ? (
                <main className="pe-3 ps-4">
                    {opacity === 1 ? (
                        <div className="SpinnerInTheMiddle">
                            <div class="spinner-border text-success center" role="status">
                                <span class="visually-hidden center">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="row pt-3 mb-3" style={{ opacity: opacity === 1 ? 0.25 : 1 }} >
                        <div className="col-9 d-flex">
                            <div class="btnBusquedaContainer shadow-lg">
                                <i class="bi bi-search pe-3"></i>
                                <input
                                    class="btnBusqueda"
                                    type="text"
                                    placeholder="BUSCAR"
                                    onChange={(e) =>
                                        setInfo((prevState) => ({
                                            ...prevState,
                                            buscarGeneral2: e.target.value,
                                        }))
                                    }
                                ></input>
                            </div>
                            <div className={cookies.user.id === 107 ? 'd-none' : "d-flex ms-5"}>
                                <div className="selectAsignadoTabla d-flex align-items-center text-white">
                                    <select
                                        id="selectOption"
                                        value={selectedOption}
                                        onChange={handleChange}
                                        className="select"
                                    >
                                        <option value="">Asignar A</option>
                                        {cookies.user.depto === "cargas"
                                            ? optionsCarga.map((option) => (
                                                <option key={option.key} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))
                                            : cookies.user.depto === "choques"
                                                ? optionsChoque.map((option) => (
                                                    <option key={option.key} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))
                                                : cookies.user.depto === "contractuales"
                                                    ? optionsContractuales.map((option) => (
                                                        <option key={option.key} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))
                                                    : optionsCarga.map((option) => (
                                                        <option key={option.key} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                    </select>
                                </div>
                                <div className="center btnEnviarAsignadoSelect ms-2">
                                    <button
                                        className="btnNO  text-white"
                                        disabled={
                                            selectedOption === ""
                                                ? true
                                                : selectedOption === null
                                                    ? true
                                                    : selectedOption === undefined
                                                        ? true
                                                        : false
                                        }
                                        onClick={(e) => actualizarAsignado(e)}
                                    >
                                        <i class="bi bi-send"></i>
                                    </button>
                                </div>
                                <div className="ms-5 center">
                                    <Link
                                        onClick={() => handleOpenInNewTab("/#/ampliaciones")}
                                        className="btn btn-danger position-relative"
                                    >
                                        Amp/Rec
                                        <span
                                            className={cookies.user.depto === 'cargas' ? "position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning" : null}
                                            style={{ zIndex: 1 }}
                                        >
                                            {cookies.user.depto === 'cargas' ? ampSinLeer?.length : null}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="d-flex center">
                                <Link
                                    to={`/${id}`}
                                    className="btnCargaArrowStro  text-white ms-3 center"
                                    style={link}
                                >
                                    <i class="bi bi-grid iconGridArrow"></i>
                                    <p className="m-0 textCargaBtnArrowStro">CARGA</p>
                                </Link>
                                <Link
                                    to={`/`}
                                    style={link}
                                    className="btnCargaArrowStro ms-3 text-white center"
                                >
                                    <i class="bi bi-bar-chart-fill iconGridArrow pe-1"></i>
                                    <p className="m-0 textCargaBtnArrowStro">TABLA</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-3" style={{ opacity: opacity === 1 ? 0.25 : 1 }}>
                        <section className="sectionTablaCargaContainer col-9">
                            <div className="my-scrollbar" style={{ maxWidth: "100%", maxHeight: "800px", overflowX: 'scroll', overflowY: 'hidden'}}>
                                <table className="table table-hover sectionTablaCarga shadow" style={{ minWidth: "6000px", tableLayout: "fixed" }}>
                                    <thead className="tablaCargaHeader tablaCarga shadow-md">
                                        <tr>
                                            {columns.map((col, index) => (
                                                <th key={index} className="ps-0 pe-0">
                                                    <h6 className="tablaCargaHeaderText">{col}</h6>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="tablaCargaBody my-scrollbar">
                                        {updatedList.length > 0 ? (
                                            updatedList.slice(itemOffset, Number(itemOffset + 200)).map((d) => (
                                                <ComponenteValoresContractuales
                                                    amp={ampSinLeer}
                                                    updatedList={updatedList}
                                                    estados={estadosNuevo}
                                                    denuncia={d}
                                                    key={d.id}
                                                />
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={columns.length + 1} className="text-center py-4">
                                                    <div className="spinner-border text-success" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <section className="d-flex justify-content-between align-items-center ps-3 pe-3">
                                <div className="">
                                    <div className="d-flex">
                                        <h6>Cantidad total: {updatedList.length}</h6>
                                        <h6 className="ps-4">
                                            Cargados:{" "}
                                            {updatedList.filter((d) => d.nroStro !== null).length}
                                        </h6>
                                        <h6 className="ps-4">
                                            Rechazados:{" "}
                                            {updatedList.filter((d) => d.estado === 2).length}
                                        </h6>
                                        <h6 className="ps-4">
                                            Sin Leer: {updatedList.filter((d) => d.estado === 0).length}
                                        </h6>
                                    </div>
                                </div>
                                <div className="">
                                    <ReactPaginate
                                        containerClassName={"custom-paginate"}
                                        pageClassName={"custom-page"}
                                        pageLinkClassName={"custom-page-link"}
                                        activeClassName={"active"}
                                        previousClassName={"custom-prev"}
                                        nextClassName={"custom-next"}
                                        breakLabel="..."
                                        previousLabel="<"
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={1}
                                        pageCount={pageCount}
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </section>
                        </section>
                        <section className="sectionFiltroTablaCarga col-3 pe-4 ps-4 pt-1">
                            <div className="row">
                                <div className="filtroCargaCol col me-2">
                                    <input
                                        class="btnBusqueda ps-3"
                                        type="text"
                                        placeholder="POLIZA"
                                        onChange={(e) =>
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                poliza: e.target.value,
                                            }))
                                        }
                                    ></input>
                                </div>
                                <div className="filtroCargaCol2 col ms-2">
                                    <input
                                        class="btnBusqueda ps-3"
                                        type="text"
                                        placeholder="FECHA STRO"
                                    ></input>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="filtroCargaCol col me-2">
                                    <input
                                        class="btnBusqueda ps-3"
                                        type="text"
                                        placeholder="NRO STRO"
                                        onChange={(e) =>
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                nroStro: e.target.value,
                                            }))
                                        }
                                    ></input>
                                </div>
                                <div className="filtroCargaCol2 col ms-2">
                                    <input
                                        class="btnBusqueda ps-3"
                                        type="text"
                                        placeholder="DOMINIO"
                                        onChange={(e) =>
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                dominio: e.target.value,
                                            }))
                                        }
                                    ></input>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="filtroCargaCol col me-2">
                                    <input
                                        class="btnBusqueda ps-3"
                                        type="text"
                                        placeholder="DOMINIO 3ro"
                                        onChange={(e) =>
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                dominio3ro: e.target.value,
                                            }))
                                        }
                                    ></input>
                                </div>
                                <div className="filtroCargaCol2 col-6 ms-2 center">
                                    <MultiSelect
                                        disabled={cookies.user.id === 107 ? true : false}
                                        className="w-100"
                                        options={optionsTipoStro}
                                        value={info.tipoStro}
                                        valueRenderer={(selected, _options) => {
                                            return selected.length
                                                ? selected.map(({ label }) => label)
                                                : "TIPO STRO";
                                        }}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                tipoStro: e,
                                                estado: [],
                                                responsabilidad: [],
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="filtroCargaCol col-6 center">
                                    {info.tipoStro[0] &&
                                        info.tipoStro[0].value === "ACCIDENTE DE TRANSITO" ? (
                                        <MultiSelect
                                            disabled={cookies.user.id === 107 ? true : false}
                                            className="w-100"
                                            options={responsabilidadChoques}
                                            value={info.catStro}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "RESPONSABILIDAD";
                                            }}
                                            onChange={(e) => {
                                                const value = Number(e);
                                                let tipoStro = null;
                                                if (value[0]) {
                                                    if (
                                                        value[0].value === 320 ||
                                                        value[0].value === 300 ||
                                                        value[0].value === 200 ||
                                                        value[0].value === 230 ||
                                                        value[0].value === 233
                                                    ) {
                                                        tipoStro = "ACCIDENTE DE TRANSITO";
                                                    } else if (value[0].value === 500) {
                                                        tipoStro = "ROBO TOTAL";
                                                    } else if (value[0].value === 400) {
                                                        tipoStro = "ROBO PARCIAL";
                                                    } else if (
                                                        value[0].value === 401 ||
                                                        value[0].value === 402 ||
                                                        value[0].value === 403 ||
                                                        value[0].value === 550 ||
                                                        value[0].value === 551
                                                    ) {
                                                        tipoStro = "ROBO PARCIAL INSP";
                                                    } else if (value[0].value === 600) {
                                                        tipoStro = "INCENDIO PARCIAL";
                                                    } else if (value[0].value === 900) {
                                                        tipoStro = "DAÑO TOTAL";
                                                    } else if (value[0].value === 800) {
                                                        tipoStro = "DAÑO PARCIAL";
                                                    }
                                                }
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    catStro: e,
                                                    estado: [],
                                                    tipoStro: value[0]
                                                        ? [{ value: tipoStro, label: tipoStro }]
                                                        : info.tipoStro,
                                                }));
                                            }}
                                        />
                                    ) : info.tipoStro[0] &&
                                        info.tipoStro[0].value === "ROBO TOTAL" ? (
                                        <MultiSelect
                                            disabled={cookies.user.id === 107 ? true : false}
                                            className="w-100"
                                            options={responsabilidadRoboTotal}
                                            value={info.catStro}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "RESPONSABILIDAD";
                                            }}
                                            onChange={(e) => {
                                                const value = Number(e);
                                                let tipoStro = null;
                                                if (value[0]) {
                                                    if (
                                                        value[0].value === 320 ||
                                                        value[0].value === 300 ||
                                                        value[0].value === 200 ||
                                                        value[0].value === 230 ||
                                                        value[0].value === 233
                                                    ) {
                                                        tipoStro = "ACCIDENTE DE TRANSITO";
                                                    } else if (value[0].value === 500) {
                                                        tipoStro = "ROBO TOTAL";
                                                    } else if (value[0].value === 400) {
                                                        tipoStro = "ROBO PARCIAL";
                                                    } else if (
                                                        value[0].value === 401 ||
                                                        value[0].value === 402 ||
                                                        value[0].value === 403 ||
                                                        value[0].value === 550 ||
                                                        value[0].value === 551
                                                    ) {
                                                        tipoStro = "ROBO PARCIAL INSP";
                                                    } else if (value[0].value === 600) {
                                                        tipoStro = "INCENDIO PARCIAL";
                                                    } else if (value[0].value === 900) {
                                                        tipoStro = "DAÑO TOTAL";
                                                    } else if (value[0].value === 800) {
                                                        tipoStro = "DAÑO PARCIAL";
                                                    }
                                                }
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    catStro: e,
                                                    estado: [],
                                                    tipoStro: value[0]
                                                        ? [{ value: tipoStro, label: tipoStro }]
                                                        : info.tipoStro,
                                                }));
                                            }}
                                        />
                                    ) : info.tipoStro[0] &&
                                        info.tipoStro[0].value === "ROBO PARCIAL" ? (
                                        <MultiSelect
                                            disabled={cookies.user.id === 107 ? true : false}
                                            className="w-100"
                                            options={responsabilidadRoboParcial}
                                            value={info.catStro}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "RESPONSABILIDAD";
                                            }}
                                            onChange={(e) => {
                                                const value = Number(e);
                                                let tipoStro = null;
                                                if (value[0]) {
                                                    if (
                                                        value[0].value === 320 ||
                                                        value[0].value === 300 ||
                                                        value[0].value === 200 ||
                                                        value[0].value === 230 ||
                                                        value[0].value === 233
                                                    ) {
                                                        tipoStro = "ACCIDENTE DE TRANSITO";
                                                    } else if (value[0].value === 500) {
                                                        tipoStro = "ROBO TOTAL";
                                                    } else if (value[0].value === 400) {
                                                        tipoStro = "ROBO PARCIAL";
                                                    } else if (
                                                        value[0].value === 401 ||
                                                        value[0].value === 402 ||
                                                        value[0].value === 403 ||
                                                        value[0].value === 550 ||
                                                        value[0].value === 551
                                                    ) {
                                                        tipoStro = "ROBO PARCIAL INSP";
                                                    } else if (value[0].value === 600) {
                                                        tipoStro = "INCENDIO PARCIAL";
                                                    } else if (value[0].value === 900) {
                                                        tipoStro = "DAÑO TOTAL";
                                                    } else if (value[0].value === 800) {
                                                        tipoStro = "DAÑO PARCIAL";
                                                    }
                                                }
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    catStro: e,
                                                    estado: [],
                                                    tipoStro: value[0]
                                                        ? [{ value: tipoStro, label: tipoStro }]
                                                        : info.tipoStro,
                                                }));
                                            }}
                                        />
                                    ) : info.tipoStro[0] &&
                                        info.tipoStro[0].value === "ROBO PARCIAL INSP" ? (
                                        <MultiSelect
                                            disabled={cookies.user.id === 107 ? true : false}
                                            className="w-100"
                                            options={responsabilidadRoboParcialInsp}
                                            value={info.catStro}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "RESPONSABILIDAD";
                                            }}
                                            onChange={(e) => {
                                                const value = Number(e);
                                                let tipoStro = null;
                                                if (value[0]) {
                                                    if (
                                                        value[0].value === 320 ||
                                                        value[0].value === 300 ||
                                                        value[0].value === 200 ||
                                                        value[0].value === 230 ||
                                                        value[0].value === 233
                                                    ) {
                                                        tipoStro = "ACCIDENTE DE TRANSITO";
                                                    } else if (value[0].value === 500) {
                                                        tipoStro = "ROBO TOTAL";
                                                    } else if (value[0].value === 400) {
                                                        tipoStro = "ROBO PARCIAL";
                                                    } else if (
                                                        value[0].value === 401 ||
                                                        value[0].value === 402 ||
                                                        value[0].value === 403 ||
                                                        value[0].value === 550 ||
                                                        value[0].value === 551
                                                    ) {
                                                        tipoStro = "ROBO PARCIAL INSP";
                                                    } else if (value[0].value === 600) {
                                                        tipoStro = "INCENDIO PARCIAL";
                                                    } else if (value[0].value === 900) {
                                                        tipoStro = "DAÑO TOTAL";
                                                    } else if (value[0].value === 800) {
                                                        tipoStro = "DAÑO PARCIAL";
                                                    }
                                                }
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    catStro: e,
                                                    estado: [],
                                                    tipoStro: value[0]
                                                        ? [{ value: tipoStro, label: tipoStro }]
                                                        : info.tipoStro,
                                                }));
                                            }}
                                        />
                                    ) : info.tipoStro[0] &&
                                        info.tipoStro[0].value === "INCENDIO TOTAL" ? (
                                        <MultiSelect
                                            disabled={cookies.user.id === 107 ? true : false}
                                            className="w-100"
                                            options={responsabilidadIncendioTotal}
                                            value={info.catStro}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "RESPONSABILIDAD";
                                            }}
                                            onChange={(e) => {
                                                const value = Number(e);
                                                let tipoStro = null;
                                                if (value[0]) {
                                                    if (
                                                        value[0].value === 320 ||
                                                        value[0].value === 300 ||
                                                        value[0].value === 200 ||
                                                        value[0].value === 230 ||
                                                        value[0].value === 233
                                                    ) {
                                                        tipoStro = "ACCIDENTE DE TRANSITO";
                                                    } else if (value[0].value === 500) {
                                                        tipoStro = "ROBO TOTAL";
                                                    } else if (value[0].value === 400) {
                                                        tipoStro = "ROBO PARCIAL";
                                                    } else if (
                                                        value[0].value === 401 ||
                                                        value[0].value === 402 ||
                                                        value[0].value === 403 ||
                                                        value[0].value === 550 ||
                                                        value[0].value === 551
                                                    ) {
                                                        tipoStro = "ROBO PARCIAL INSP";
                                                    } else if (value[0].value === 600) {
                                                        tipoStro = "INCENDIO PARCIAL";
                                                    } else if (value[0].value === 900) {
                                                        tipoStro = "DAÑO TOTAL";
                                                    } else if (value[0].value === 800) {
                                                        tipoStro = "DAÑO PARCIAL";
                                                    }
                                                }
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    catStro: e,
                                                    estado: [],
                                                    tipoStro: value[0]
                                                        ? [{ value: tipoStro, label: tipoStro }]
                                                        : info.tipoStro,
                                                }));
                                            }}
                                        />
                                    ) : info.tipoStro[0] &&
                                        info.tipoStro[0].value === "INCENDIO PARCIAL" ? (
                                        <MultiSelect
                                            disabled={cookies.user.id === 107 ? true : false}
                                            className="w-100"
                                            options={responsabilidadIncendioParcial}
                                            value={info.catStro}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "RESPONSABILIDAD";
                                            }}
                                            onChange={(e) => {
                                                const value = Number(e);
                                                let tipoStro = null;
                                                if (value[0]) {
                                                    if (
                                                        value[0].value === 320 ||
                                                        value[0].value === 300 ||
                                                        value[0].value === 200 ||
                                                        value[0].value === 230 ||
                                                        value[0].value === 233
                                                    ) {
                                                        tipoStro = "ACCIDENTE DE TRANSITO";
                                                    } else if (value[0].value === 500) {
                                                        tipoStro = "ROBO TOTAL";
                                                    } else if (value[0].value === 400) {
                                                        tipoStro = "ROBO PARCIAL";
                                                    } else if (
                                                        value[0].value === 401 ||
                                                        value[0].value === 402 ||
                                                        value[0].value === 403 ||
                                                        value[0].value === 550 ||
                                                        value[0].value === 551
                                                    ) {
                                                        tipoStro = "ROBO PARCIAL INSP";
                                                    } else if (value[0].value === 600) {
                                                        tipoStro = "INCENDIO PARCIAL";
                                                    } else if (value[0].value === 900) {
                                                        tipoStro = "DAÑO TOTAL";
                                                    } else if (value[0].value === 800) {
                                                        tipoStro = "DAÑO PARCIAL";
                                                    }
                                                }
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    catStro: e,
                                                    estado: [],
                                                    tipoStro: value[0]
                                                        ? [{ value: tipoStro, label: tipoStro }]
                                                        : info.tipoStro,
                                                }));
                                            }}
                                        />
                                    ) : info.tipoStro[0] &&
                                        info.tipoStro[0].value === "DAÑO TOTAL" ? (
                                        <MultiSelect
                                            disabled={cookies.user.id === 107 ? true : false}
                                            className="w-100"
                                            options={responsabilidadDanioTotal}
                                            value={info.catStro}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "RESPONSABILIDAD";
                                            }}
                                            onChange={(e) => {
                                                const value = Number(e);
                                                let tipoStro = null;
                                                if (value[0]) {
                                                    if (
                                                        value[0].value === 320 ||
                                                        value[0].value === 300 ||
                                                        value[0].value === 200 ||
                                                        value[0].value === 230 ||
                                                        value[0].value === 233
                                                    ) {
                                                        tipoStro = "ACCIDENTE DE TRANSITO";
                                                    } else if (value[0].value === 500) {
                                                        tipoStro = "ROBO TOTAL";
                                                    } else if (value[0].value === 400) {
                                                        tipoStro = "ROBO PARCIAL";
                                                    } else if (
                                                        value[0].value === 401 ||
                                                        value[0].value === 402 ||
                                                        value[0].value === 403 ||
                                                        value[0].value === 550 ||
                                                        value[0].value === 551
                                                    ) {
                                                        tipoStro = "ROBO PARCIAL INSP";
                                                    } else if (value[0].value === 600) {
                                                        tipoStro = "INCENDIO PARCIAL";
                                                    } else if (value[0].value === 900) {
                                                        tipoStro = "DAÑO TOTAL";
                                                    } else if (value[0].value === 800) {
                                                        tipoStro = "DAÑO PARCIAL";
                                                    }
                                                }
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    catStro: e,
                                                    estado: [],
                                                    tipoStro: value[0]
                                                        ? [{ value: tipoStro, label: tipoStro }]
                                                        : info.tipoStro,
                                                }));
                                            }}
                                        />
                                    ) : info.tipoStro[0] &&
                                        info.tipoStro[0].value === "DAÑO PARCIAL" ? (
                                        <MultiSelect
                                            disabled={cookies.user.id === 107 ? true : false}
                                            className="w-100"
                                            options={responsabilidadDanioParcial}
                                            value={info.catStro}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "RESPONSABILIDAD";
                                            }}
                                            onChange={(e) => {
                                                const value = Number(e);
                                                let tipoStro = null;
                                                if (value[0]) {
                                                    if (
                                                        value[0].value === 320 ||
                                                        value[0].value === 300 ||
                                                        value[0].value === 200 ||
                                                        value[0].value === 230 ||
                                                        value[0].value === 233
                                                    ) {
                                                        tipoStro = "ACCIDENTE DE TRANSITO";
                                                    } else if (value[0].value === 500) {
                                                        tipoStro = "ROBO TOTAL";
                                                    } else if (value[0].value === 400) {
                                                        tipoStro = "ROBO PARCIAL";
                                                    } else if (
                                                        value[0].value === 401 ||
                                                        value[0].value === 402 ||
                                                        value[0].value === 403 ||
                                                        value[0].value === 550 ||
                                                        value[0].value === 551
                                                    ) {
                                                        tipoStro = "ROBO PARCIAL INSP";
                                                    } else if (value[0].value === 600) {
                                                        tipoStro = "INCENDIO PARCIAL";
                                                    } else if (value[0].value === 900) {
                                                        tipoStro = "DAÑO TOTAL";
                                                    } else if (value[0].value === 800) {
                                                        tipoStro = "DAÑO PARCIAL";
                                                    }
                                                }
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    catStro: e,
                                                    estado: [],
                                                    tipoStro: value[0]
                                                        ? [{ value: tipoStro, label: tipoStro }]
                                                        : info.tipoStro,
                                                }));
                                            }}
                                        />
                                    ) : (
                                        <MultiSelect
                                            disabled={cookies.user.id === 107 ? true : false}
                                            className="w-100"
                                            options={responsabilidadInput}
                                            value={info.catStro}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "RESPONSABILIDAD";
                                            }}
                                            onChange={(e) => {
                                                const value = e;
                                                let tipoStro = null;
                                                if (value[0]) {
                                                    if (
                                                        value[0].value === 320 ||
                                                        value[0].value === 300 ||
                                                        value[0].value === 200 ||
                                                        value[0].value === 230 ||
                                                        value[0].value === 233
                                                    ) {
                                                        tipoStro = "ACCIDENTE DE TRANSITO";
                                                    } else if (value[0].value === 500) {
                                                        tipoStro = "ROBO TOTAL";
                                                    } else if (value[0].value === 400) {
                                                        tipoStro = "ROBO PARCIAL";
                                                    } else if (
                                                        value[0].value === 401 ||
                                                        value[0].value === 402 ||
                                                        value[0].value === 403 ||
                                                        value[0].value === 550 ||
                                                        value[0].value === 551
                                                    ) {
                                                        tipoStro = "ROBO PARCIAL INSP";
                                                    } else if (value[0].value === 600) {
                                                        tipoStro = "INCENDIO PARCIAL";
                                                    } else if (value[0].value === 900) {
                                                        tipoStro = "DAÑO TOTAL";
                                                    } else if (value[0].value === 800) {
                                                        tipoStro = "DAÑO PARCIAL";
                                                    }
                                                }
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    catStro: e,
                                                    estado: [],
                                                    tipoStro: value[0]
                                                        ? [{ value: tipoStro, label: tipoStro }]
                                                        : info.tipoStro,
                                                }));
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="filtroCargaCol2 col-6 center">
                                    {cookies.user.id === 107 ?
                                        <MultiSelect
                                            className="w-100"
                                            options={estadosNuevo
                                                .filter((estado) => [311, 302].includes(estado.id))
                                                .map((estado) => ({
                                                    label: estado.nombre,
                                                    value: estado.id
                                                }))
                                            }
                                            value={info.estado}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "ESTADO";
                                            }}
                                            onChange={(e) => {
                                                if (e.some(option => option.value === 'separator')) return;
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    estado: e,
                                                }));
                                            }}
                                        />

                                        :
                                        <MultiSelect
                                            className="w-100"
                                            options={
                                                info.tipoStro.length > 0 ?
                                                    [
                                                        ...estadosNuevo
                                                            .filter((estado) => {
                                                                const a = codigos.filter((option) =>
                                                                    info.tipoStro.some((tipo) => tipo.value === option.tipoStro)
                                                                );
                                                                if (a.length > 0) {
                                                                    return a.some((item) => {
                                                                        const estadosPermitidos = item.estados.split(',').map(Number);
                                                                        return estadosPermitidos.includes(estado.id);
                                                                    });
                                                                }
                                                                return false;
                                                            })
                                                            .map((estado) => ({
                                                                label: estado.nombre,
                                                                value: estado.id
                                                            })),
                                                        ...estadosNuevo
                                                            .filter((estado) => [200, 201, 203].includes(estado.id))
                                                            .map((estado) => ({
                                                                label: estado.nombre,
                                                                value: estado.id
                                                            }))
                                                    ]
                                                    : estadosNuevo
                                                        .filter((estado) => [200, 201, 203].includes(estado.id))
                                                        .map((estado) => ({
                                                            label: estado.nombre,
                                                            value: estado.id
                                                        }))

                                            }

                                            value={info.estado}
                                            valueRenderer={(selected, _options) => {
                                                return selected.length
                                                    ? selected.map(({ label }) => label)
                                                    : "ESTADO";
                                            }}
                                            onChange={(e) => {
                                                if (e.some(option => option.value === 'separator')) return;
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    estado: e,
                                                }));
                                            }}
                                        />}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="filtroCargaCol2 col-6 center">
                                    <MultiSelect
                                        disabled={cookies.user.id === 107 ? true : false}
                                        className="w-100"
                                        options={
                                            cookies.user.depto === "cargas"
                                                ? optionsCarga
                                                : cookies.user.depto === "choques"
                                                    ? optionsChoque
                                                    : cookies.user.depto === "contractuales"
                                                        ? optionsContractuales
                                                        : optionsCarga
                                        }
                                        value={info.asignado}
                                        valueRenderer={(selected, _options) => {
                                            return selected.length
                                                ? selected.map(({ label }) => label)
                                                : "ASIGNADO A";
                                        }}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                asignado: e,
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="filtroCargaCol2 col-6 center">
                                    <MultiSelect
                                        disabled={cookies.user.id === 107 ? true : false}
                                        className="w-100"
                                        options={sicobeOptions}
                                        value={info.sicobe}
                                        valueRenderer={(selected, _options) => {
                                            return selected.length
                                                ? selected.map(({ label }) => label)
                                                : "CBT SICOBE";
                                        }}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                sicobe: e,
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="filtroCargaCol2 col-6 center">
                                    <MultiSelect
                                        disabled={cookies.user.id === 107 ? true : false}
                                        className="w-100"
                                        options={optionsPrioridad}
                                        value={info.prioridad}
                                        valueRenderer={(selected, _options) => {
                                            return selected.length
                                                ? selected.map(({ label }) => label)
                                                : "PRIORIDAD";
                                        }}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                prioridad: e,
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="filtroCargaCol2 col-6 center">
                                    <MultiSelect
                                        disabled={cookies.user.id === 107 ? true : false}
                                        className="w-100"
                                        options={optionsVencimiento}
                                        value={info.vencimiento}
                                        valueRenderer={(selected, _options) => {
                                            return selected.length
                                                ? selected.map(({ label }) => label)
                                                : "VENCIMIENTO";
                                        }}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                vencimiento: e,
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="center mt-1 mb-1">
                                <hr className="w-100" />
                            </div>
                            <h5 className="center text-secondary mb-2">
                                {isCalendar == 2
                                    ? "Filtrar Fecha Reclamo Recibido"
                                    : isCalendar == 1
                                        ? "Filtrar Fecha Vencimiento"
                                        : "Filtrar Fecha Recepcion"}
                            </h5>
                            <div className="center">
                                <Calendar
                                    locale="es-419"
                                    onChange={onChange}
                                    className="my-custom-calendar"
                                />
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <select
                                    value={isCalendar}
                                    onChange={(e) => {
                                        onChange(null);
                                        handleChange1(e);
                                    }}
                                    className="select1 m-0"
                                    style={{ height: "36px", width: "50%" }}
                                >
                                    <option value="">Calendario</option>
                                    {optionsCalendar.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={(e) => onChange(null)}
                                >
                                    Quitar Filtro Fecha
                                </button>
                            </div>
                        </section>
                    </div>
                </main>
            ) : (
                <h1>Falta Iniciar Sesion</h1>
            )}
        </>
    );
}
