import { useCallback, useEffect, useRef, } from "react";
import "./stro.css";
import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import './choquesStro.css'
import { useCookies } from 'react-cookie';
import MensajesCronologiaCarga from "../components/carga/MensajesCronologiaMensajes";
import QnxSeccion from '../components/carga/QnxSeccion'
import DenunciaAdminSeccion from '../components/carga/DenunciaAdminSeccion'
import Select from "react-select";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Swal from "sweetalert2";
import Header from '../components/general/Header'
import ModuloActualizacionStro from "../components/stro/ModuloActualizacionStro"
import ModuloActualizacionStro1 from "../components/stro/ModuloActualizacionStro1";
import './offCanvas.css'
import PDF from "../components/pdf/Sicobe";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 3 });
export default function StroCarga() {
    const id = useParams().id;
    useEffect(() => {
        localStorage.setItem('id', id);
    }, []);
    const [cookies] = useCookies(["user"], ['asignado']);
    const asignado = cookies.asignado
    const [denuncia, setDenuncia] = useState([]);
    const [cargando, setCargando] = useState(false)
    const [obs, setObs] = useState();
    const [responsabilidad, setResponsabilidad] = useState([])
    const [denunciaParaSicobe, setDenunciaParaSicobe] = useState(null)
    const [frente, setFrente] = useState({})
    const [sicobe, setSicobe] = useState([])
    const [pagosAnteriores1, setPagosAnteriores1] = useState([])
    const [cobert, setCobert] = useState('')
    const [carga2, setCarga2] = useState(false)
    const [alerta, setAlerta] = useState(0)
    const alerta1 = useRef(false);
    const [opacity, setOpacity] = useState(0)
    const [estados, setEstados] = useState([])
    const [estadosCodigos, setEstadosCodigos] = useState([])
    const [carga3, setCarga3] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resDenuncia = await axios.get(`https://back.agsseguros.online/api/carga/pruebaCargaSinNada/${id}`);
                setDenuncia(resDenuncia.data);
                setResponsabilidad(resDenuncia.data.cobert);
                setObs(resDenuncia.data.obsPrevias);
                setCargando(true)
                const resEstados = await axios.get(`https://back.agsseguros.online/api/estados/estadosContractuales/${id}`);
                setEstados(resEstados.data);
                const estadosCod = await axios.get(`https://back.agsseguros.online/api/estados/codigosParaEstado/${id}`)
                setEstadosCodigos(estadosCod.data)
                setCarga3(true);
                const resSicobe = await axios.get(`https://back.agsseguros.online/api/tieneCobertura/${id}`);
                setSicobe(resSicobe.data);
                const duplicados = resDenuncia.data.duplicados !== undefined ? resDenuncia.data.duplicados : null;
                if (duplicados !== null && duplicados.length > 0) { setAlerta(1) }
                // pagos que existen
                const pagos = resSicobe.data.pagos;
                // plan de pagos
                const arrayFechaLimite = resSicobe.data.fechaLimiteWe
                const fechaStro = new Date(resSicobe.data.fechaStro);
                const pagosAnteriores = [];
                // por cada elemento del array fechaLimite (plan de pagos), si la fecha del stro fue mayor que la fecha limite, se pushea dicho elemento
                for (let i = 0; i < arrayFechaLimite.length; i++) {
                    if (i === 0) {
                        pagosAnteriores.push([i]);
                    } else {
                        if (new Date(arrayFechaLimite[i]) < fechaStro) {
                            pagosAnteriores.push([i]);
                        }
                    }
                }
                setPagosAnteriores1(pagosAnteriores);
                const totalImporteFechaStro = pagos.slice(0, pagosAnteriores1.length).map(item => parseFloat(item?.pga_importe) || 0).reduce((acc, importe) => acc + importe, 0);
                const totalImporteEsperado = (parseFloat(pagos[0]?.pga_importe) || 0) * pagosAnteriores1.length;
                if (pagos.length === 0) {
                    setCobert('CON COBERTURA')
                } else if (pagos[0]?.pga_mp === 'TA') {
                    setCobert('CON COBERTURA');
                } else if (totalImporteFechaStro !== totalImporteEsperado) {
                    setCobert('EN REVISION');
                } else if (pagosAnteriores.length > 0 && pagosAnteriores.length <= pagos.length) {
                    const ultimoPagoAnterior = pagos[pagosAnteriores.length - 1];
                    const pgaFechaAlta = new Date(ultimoPagoAnterior.pga_fliqags);
                    const fechaLimite = new Date(arrayFechaLimite[pagosAnteriores.length - 1]);
                    if (pgaFechaAlta <= fechaLimite) {
                        setCobert('CON COBERTURA');
                    } else if (pgaFechaAlta > fechaLimite) {
                        if (fechaStro >= pgaFechaAlta) {
                            setCobert('CON COBERTURA');
                        } else {
                            setCobert('EN REVISION');
                        }
                    }
                } else {
                    setCobert('EN REVISION')
                }
                const resFrente = await axios.get(`https://back.agsseguros.online/api/frente/${id}`);
                setFrente(resFrente.data);
                setCarga2(true);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [id]);
    const esAsignado = asignado?.findIndex(e => e.id === denuncia?.id)
    const asignadoMas1 = asignado[esAsignado + 1]
    const asignadoMenos1 = asignado[esAsignado - 1]
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    const year = today.getFullYear();
    day = (day < 10) ? '0' + day : day;
    month = (month < 10) ? '0' + month : month;
    const formattedDate = day + '/' + month + '/' + year;
    function getCurrentTime() {
        const now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        minute = (minute < 10) ? '0' + minute : minute;
        return hour + ':' + minute;
    }
    const currentTime = getCurrentTime();
    const [mensaje, setMensaje] = useState({ obs: null, motivo: null })
    const motivoMensajeInput = [{ label: "CTR", value: "CTR" }, { label: "AMPL", value: "AMPL" }]
    const comentarioUpdate = useCallback(async (e) => {
        e.preventDefault()
        setOpacity(1)
        await axios.post(`https://back.agsseguros.online/api/cronologia`,
            {
                id: denuncia.id,
                desc: '-',
                obs: mensaje.obs,
                jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                motivo: mensaje.motivo,
                autor: cookies.user.usuario,
                fecha: formattedDate,
                hora: currentTime
            }
        ).then((response) => { if (response) { window.location.reload() } })
    }, [mensaje, denuncia, cookies, currentTime, formattedDate]);
    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white'
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? '#42C3BA'
                : isSelected
                    ? '#42C3BA'
                    : undefined,
            border: isFocused
                ? '#42C3BA 1px solid'
                : isSelected
                    ? '#42C3BA 1px solid'
                    : undefined,
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        })
    }
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const qnxInic = formatDate(denuncia?.qnx?.ados_fech_ini);
    const qnxFin = formatDate(denuncia?.qnx?.ados_fech_fin);
    const fechaStro = formatDate(denuncia?.fechaStro);
    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('/');
        return new Date(`${year}-${month}-${day}`);
    };
    const isDateBetween = (date, startDate, endDate) => {
        const dateToCheck = parseDate(date);
        const start = parseDate(startDate);
        const end = parseDate(endDate);

        const isSameDay = (d1, d2) => (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );

        if (isSameDay(dateToCheck, start) || isSameDay(dateToCheck, end)) {
            return false;
        }

        return dateToCheck > start && dateToCheck < end;
    };
    const [check, setChecked] = useState({
        fotos: false,
        cedula: false,
        croquis: false,
        relato: false,
        danios: false,
        registro: false,
        denunciaPol: false,
    });
    const [docu, setDocu] = useState({
        fotos: "",
        cedula: "",
        croquis: "",
        relato: "",
        danios: "",
        registro: "",
        denunciaPol: "",
        otros: "",
    });
    function handleChangeFotos(e) {
        setChecked((prevState) => ({ ...prevState, fotos: e.target.checked }));
        if (!check.fotos) {
            setDocu((prevState) => ({
                ...prevState,
                fotos: "Fotos \n",
            }));
        } else if (check.fotos) {
            setDocu((prevState) => ({
                ...prevState,
                fotos: "",
            }));
        }
    }
    function handleChangeCedula(e) {
        setChecked((prevState) => ({ ...prevState, cedula: e.target.checked }));
        if (!check.cedula) {
            setDocu((prevState) => ({
                ...prevState,
                cedula: "Cedula \n",
            }));
        } else if (check.cedula) {
            setDocu((prevState) => ({
                ...prevState,
                cedula: "",
            }));
        }
    }
    function handleChangeCroquis(e) {
        setChecked((prevState) => ({ ...prevState, croquis: e.target.checked }));
        if (!check.croquis) {
            setDocu((prevState) => ({
                ...prevState,
                croquis: "Croquis \n",
            }));
        } else if (check.croquis) {
            setDocu((prevState) => ({
                ...prevState,
                croquis: "",
            }));
        }
    }
    function handleChangeRelato(e) {
        setChecked((prevState) => ({ ...prevState, relato: e.target.checked }));
        if (!check.relato) {
            setDocu((prevState) => ({
                ...prevState,
                relato: "Relato \n",
            }));
        } else if (check.relato) {
            setDocu((prevState) => ({
                ...prevState,
                relato: "",
            }));
        }
    }
    function handleChangeDanios(e) {
        setChecked((prevState) => ({ ...prevState, danios: e.target.checked }));
        if (!check.danios) {
            setDocu((prevState) => ({
                ...prevState,
                danios: "Daños \n",
            }));
        } else if (check.danios) {
            setDocu((prevState) => ({
                ...prevState,
                danios: "",
            }));
        }
    }
    function handleChangeRegistro(e) {
        setChecked((prevState) => ({ ...prevState, registro: e.target.checked }));
        if (!check.registro) {
            setDocu((prevState) => ({
                ...prevState,
                registro: "Registro \n",
            }));
        } else if (check.registro) {
            setDocu((prevState) => ({
                ...prevState,
                registro: "",
            }));
        }
    }
    function handleChangeDenunciaPol(e) {
        setChecked((prevState) => ({
            ...prevState,
            denunciaPol: e.target.checked,
        }));
        if (!check.denunciaPol) {
            setDocu((prevState) => ({
                ...prevState,
                denunciaPol: "Denuncia Pol \n",
            }));
        } else if (check.denunciaPol) {
            setDocu((prevState) => ({
                ...prevState,
                denunciaPol: "",
            }));
        }
    }
    const responsabilidadInput = [
        { label: "Cr", value: 320 },
        { label: "Cc", value: 320 },
        { label: "Sr", value: 300 },
        { label: "Rp", value: 400 },
        { label: "Rp1 (1 ruedas)", value: 401 },
        { label: "Rp2 (2 ruedas)", value: 402 },
        { label: "Rp3 (3 ruedas)", value: 403 },
        { label: "Rp4 (4 ruedas)", value: 404 },
        { label: "Rp5 (5 ruedas)", value: 405    },
        { label: "Rt", value: 500 },
        { label: "Ra", value: 550 },
        { label: "Raf", value: 551 },
        { label: "Ip", value: 600 },
        { label: "Dp", value: 800 },
        { label: "Dt", value: 900 },
        { label: "Aseg les", value: 100 },
        { label: "Aseg muerto", value: 130 },
        { label: "3ro les", value: 200 },
        { label: "3ro muerto", value: 230 },
        { label: "3ro muerto Rec", value: 233 },
        {label: 'Rah', value: 590}
    ];
    const handleChange1 = (event) => {
        setResponsabilidad(event.target.value);
    };
    const stroRechazado = useCallback(
        async (e) => {
            e.preventDefault();
            Swal.fire({
                title: `Esta seguro que desea rechazar la denuncia ${denuncia.pza}`,
                icon: "warning",
                confirmButtonText: "Confirmar",
                showCancelButton: true,
            }).then(async (result) => {
                setOpacity(1)
                if (result.isConfirmed) {
                    await axios.post(`https://back.agsseguros.online/api/actualizarEstadoRechazado`, { estado: 203, id: denuncia.id }).then(async (res) => {
                        await axios.post(`https://back.agsseguros.online/api/cronologia`, {
                            id: denuncia.id,
                            desc: "La denuncia ha sido rechazada",
                            obs: obs,
                            jerarquia: cookies.user.depto === "cargas" ? "CRG" : "DEN",
                            motivo: "STRO",
                            autor: cookies.user.usuario,
                            fecha: formattedDate,
                            hora: currentTime,
                        }).then(() => {
                            Swal.fire({
                                title: `La denuncia ${denuncia.pza} se ha catalogado como rechazado correctamente`,
                                icon: "success",
                                confirmButtonText: "oK",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                            });
                        });
                    });
                } else if (result.isDenied) {
                }
            });
        },
        [denuncia, obs, currentTime, formattedDate, cookies]
    );
    const stroAmpliacion = useCallback(
        async (e) => {
            e.preventDefault();
            Swal.fire({
                title: `Esta seguro que desea pedir ampliacion de la denuncia ${denuncia.pza}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Confirmar",
            }).then(async (result) => {
                setOpacity(1)
                if (result.isConfirmed) {
                    Swal.fire(
                        `Estimados, solicitamos que nos envíen la siguiente documentación faltante:`,
                        `${docu.fotos} ${docu.cedula} ${docu.croquis} ${docu.relato} ${docu.danios} ${docu.registro} ${docu.denunciaPol} \n\n${docu.otros}`,
                        "success"
                    ).then(async () => {
                        await axios
                            .post(`https://back.agsseguros.online/api/cronologia`, {
                                id: denuncia.id,
                                desc: `Se ha solicitado la siguiente documentación: ${docu.fotos} ${docu.cedula} ${docu.croquis} ${docu.relato} ${docu.danios} ${docu.registro} ${docu.denunciaPol} \n\n${docu.otros}`,
                                obs: obs,
                                jerarquia: cookies.user.depto === "cargas" ? "CRG" : "DEN",
                                motivo: "AMPL",
                                autor: cookies.user.usuario,
                                fecha: formattedDate,
                                hora: currentTime,
                            })
                            .then(() => {
                                window.location.reload();
                            });
                    });
                }
            });
        },
        [denuncia, docu, obs, cookies, currentTime, formattedDate]
    );
    const [fueCargado, setFueCargado] = useState(false)
    const stroCargado = useCallback(async (e) => {
        e.preventDefault();
        Swal.fire({
            title: `Esta seguro que desea cargar la denuncia ${denuncia.pza}`,
            icon: "warning",
            confirmButtonText: "Confirmar",
            showCancelButton: true,
        }).then(async (result) => {
            setOpacity(1)
            if (result.isConfirmed) {
                setFueCargado(true)
                await axios.post(`https://back.agsseguros.online/api/carga/cargarDenunciaNuevo`,
                    {
                        estado: (Number(denuncia.estadoNuevo) !== 200 && Number(denuncia.estadoNuevo) !== 201 && Number(denuncia.estadoNuevo) !== 203) ? Number(denuncia.estadoNuevo) : 201,
                        id: denuncia.id,
                        responsabilidad: Number(responsabilidad),
                        tipoStro: denuncia.tipoStro,
                        cbtSicobe: cobert === 'CON COBERTURA' ? 1 : (cobert === 'EN REVISION' ? 2 : 0)
                    }).then(async (res) => {
                        await axios.post(`https://back.agsseguros.online/api/cronologia`, {
                            id: denuncia.id,
                            desc: "El stro fue cargado correctamente",
                            obs: obs,
                            jerarquia: cookies.user.depto === "cargas" ? "CRG" : "DEN",
                            motivo: "STRO",
                            autor: cookies.user.usuario,
                            fecha: formattedDate,
                            hora: currentTime,
                        });
                        await axios.post(`https://back.agsseguros.online/api/mail/mailNuevaCargaPas`, {
                            id: denuncia.id,
                            mail: res.data.email,
                            pza: res.data.pza,
                            patente: res.data.dom,
                            fechaStro: formatDate(res.data.fechaStro),
                            nroStro: res.data.nroStro.toString().substring(3)

                        });
                        await axios.post(`https://back.agsseguros.online/api/mail/mailNuevaCargaPasAugusto`, {
                            id: denuncia.id,
                            pza: res.data.pza,
                            patente: res.data.dom,
                            fechaStro: formatDate(res.data.fechaStro),
                            nroStro: res.data.nroStro.toString().substring(3)

                        });
                        setDenunciaParaSicobe(res.data.nroStro.toString().substring(3))
                        Swal.fire({
                            title: `El Stro ${res.data.nroStro.toString().substring(3)} se ha cargado correctamente`,
                            icon: "success",
                            confirmButtonColor: "#42C3BA",
                            confirmButtonText: "Descargar Sicobe",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                document.getElementById('botonSicobe').click();
                                window.location.reload();
                            } else {
                                window.location.reload();
                            }
                        });
                    });
            } else if (result.isDenied) {
            }
        });
    }, [denuncia, obs, currentTime, formattedDate, cookies, responsabilidad]
    );
    function getLabelsFromValues(dbValue, responsabilidadInput) {
        const valuesArray = dbValue.split(',').map(Number);
        const labels = valuesArray.map(value => {
            const item = responsabilidadInput.find(item => item.value === value);
            return item ? item.label : null;
        }).filter(label => label !== null);
        return labels.join(', ');
    }
    useEffect(() => {
        if (alerta === 1 && !alerta1.current) {
            const duplicados = denuncia?.duplicados || [];
            const links = duplicados.map(d => `<a href="https://app.agsseguros.online/#/${d.id}" target="_blank">${d.entryId}</a>`).join('<br/>');
            Swal.fire({
                title: 'Posibles duplicados encontrados',
                html: `${links}`,
                icon: 'info',
                confirmButtonText: 'Aceptar'
            });
            setAlerta(0);
            alerta1.current = true; // Marca que la alerta ya se mostró
        }
    }, [alerta, denuncia]);
    console.log(denuncia.catStro);
    console.log(responsabilidadInput);
    
    return (
        <>
            <Header denuncia={denuncia} asignadoMas1={asignadoMas1} asignadoMenos1={asignadoMenos1} />
            <main>
                {opacity === 1 ? <div className="SpinnerInTheMiddle"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div> : <></>}
                {cargando ?
                    <div className="rowMain" style={{ opacity: opacity === 1 ? 0.25 : 1 }}>
                        <section className="columnMain">
                            <QnxSeccion denuncia={denuncia} sicobe={sicobe} frente={frente} pagosAnteriores={pagosAnteriores1} cobert={cobert} carga2={carga2} />
                            <section className="divFechasCargasStro d-flex ">
                                <div className="seccion1StroHeader w-100 pe-5 ps-5">
                                    <div className="div12StroHeader row">
                                        <div className="col-6 d-flex align-items-center">
                                            <label className="labelStro">Fecha Carga</label>
                                            <div className="inputFechaCargaHeaderStro d-flex align-items-center ps-3 textoCarga">
                                                {denuncia.nroStro !== null ? formatDate(denuncia.fechaCarga) : ''}
                                            </div>
                                        </div>
                                        <div className="col-6 d-flex align-items-center">
                                            <label className="labelStro">Fecha Sello</label>
                                            <div className="inputFechaCargaHeaderStro d-flex align-items-center ps-3 textoCarga">
                                                {formatDate(denuncia.fechaDenuncia)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div1StroHeader2 row">
                                        <div className="col-6 d-flex align-items-center">
                                            <label className="labelStro">Responsabilidad</label>
                                            {denuncia.catStro !== null ?
                                                <div className="inputFechaCargaHeaderStro d-flex align-items-center ps-3 textoCarga">
                                                    {getLabelsFromValues(denuncia?.catStro, responsabilidadInput)}
                                                </div> :
                                                <select value={responsabilidad} onChange={handleChange1} className="select1 m-0" style={{ height: '48px', width: '100%' }}>
                                                    <option value="">Select...</option>
                                                    {responsabilidadInput.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>}
                                        </div>
                                        <div className="col-6 d-flex align-items-center">
                                            <label className="labelStro">Vigencia</label>
                                            <div className={isDateBetween(fechaStro, qnxInic, qnxFin) ? "btnCoberturaFechaCargaHeaderStro text-center center text-white" : "btnCoberturaFechaCargaHeaderStroRojo text-center center text-white"}>{isDateBetween(fechaStro, qnxInic, qnxFin) ? "Vigencia" : "Sin Vigencia"}</div>
                                        </div>
                                    </div>
                                    <div className="div1StroHeader2 row">
                                        <div className="align-items-start">
                                            <label className="labelStro">Observaciones</label>
                                            <textarea
                                                className="inputFechaCargaHeaderStroObservaciones mt-1 w-100 pt-1 ps-3"
                                                value={obs}
                                                onChange={(e) =>
                                                    setObs(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="div1StroHeader2 row">
                                        <div className="col-6"></div>
                                        <div className="col-6 d-flex">
                                            <div className="btnSolicitudFecha center">
                                                <button
                                                    className="btnNO text-white btnFechasCargasStro"
                                                    onClick={(e) => stroRechazado(e)}
                                                >
                                                    RECHAZAR
                                                </button>
                                            </div>
                                            <div className="btnDenunciaInc center">
                                                <button
                                                    className="btnNO text-white btnFechasCargasStro"
                                                    onClick={(e) => stroAmpliacion(e)}
                                                    disabled={
                                                        docu.cedula === "" &&
                                                            docu.croquis === "" &&
                                                            docu.danios === "" &&
                                                            docu.denunciaPol === "" &&
                                                            docu.fotos === "" &&
                                                            docu.otros === "" &&
                                                            docu.registro === "" &&
                                                            docu.relato === ""
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    DENUNCIA INC
                                                </button>
                                            </div>
                                            <div className="btnCargarStro center">
                                                <button className="btnNO text-white btnFechasCargasStro"
                                                    disabled={cargando === false ? true : (denuncia.nroStro !== null ? true : (responsabilidad === null ? true : (responsabilidad === '' ? true : false)))}
                                                    onClick={(e) => stroCargado(e)}>
                                                    {cargando === true ? 'CARGAR STRO' : <div className="center"><div class="spinner-border text-white center" role="status"><span class="visually-hidden center">Loading...</span></div></div>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                        <section className="columnMain">
                            <ModuloActualizacionStro1 denuncia={denuncia} estadosCodigos={estadosCodigos} estadosValores={estados} carga3={carga3}/>
                            <section className="infoStroTabla2">
                                <DenunciaAdminSeccion denuncia={denuncia} />
                                <hr className="" />
                                <section className="mt-3">
                                    <div className="d-flex">
                                        <div className="d-flex me-3">
                                            <input
                                                type="checkbox"
                                                name=""
                                                id=""
                                                onChange={handleChangeFotos}
                                                checked={check.fotos}
                                                class="form-check-input me-1"
                                            />
                                            <label htmlFor="" className="checkAmpliacionContainer">
                                                FOTOS
                                            </label>
                                        </div>
                                        <div className="d-flex me-3">
                                            <input
                                                type="checkbox"
                                                name=""
                                                id=""
                                                class="form-check-input me-1"
                                                onChange={handleChangeCedula}
                                                checked={check.cedula}
                                            />
                                            <label htmlFor="" className="checkAmpliacionContainer">
                                                CEDULA
                                            </label>
                                        </div>
                                        <div className="d-flex me-3">
                                            <input
                                                type="checkbox"
                                                name=""
                                                id=""
                                                class="form-check-input me-1"
                                                onChange={handleChangeCroquis}
                                                checked={check.croquis}
                                            />
                                            <label htmlFor="" className="checkAmpliacionContainer">
                                                CROQUIS
                                            </label>
                                        </div>
                                        <div className="d-flex me-3">
                                            <input
                                                type="checkbox"
                                                name=""
                                                id=""
                                                class="form-check-input me-1"
                                                onChange={handleChangeRelato}
                                                checked={check.relato}
                                            />
                                            <label htmlFor="" className="checkAmpliacionContainer">
                                                RELATO
                                            </label>
                                        </div>
                                        <div className="d-flex me-3">
                                            <input
                                                type="checkbox"
                                                name=""
                                                id=""
                                                class="form-check-input me-1"
                                                onChange={handleChangeDanios}
                                                checked={check.danios}
                                            />
                                            <label htmlFor="" className="checkAmpliacionContainer">
                                                DAÑOS
                                            </label>
                                        </div>
                                        <div className="d-flex me-3">
                                            <input
                                                type="checkbox"
                                                name=""
                                                id=""
                                                class="form-check-input me-1"
                                                onChange={handleChangeRegistro}
                                                checked={check.registro}
                                            />
                                            <label htmlFor="" className="checkAmpliacionContainer">
                                                REGISTRO
                                            </label>
                                        </div>
                                        <div className="d-flex me-3">
                                            <input
                                                type="checkbox"
                                                name=""
                                                id=""
                                                class="form-check-input me-1"
                                                onChange={handleChangeDenunciaPol}
                                                checked={check.denunciaPol}
                                            />
                                            <label htmlFor="" className="checkAmpliacionContainer">
                                                DENUNCIA POL
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start mt-2">
                                        <label className="labelStro1 pt-2">OTROS</label>
                                        <textarea
                                            className="inputStroAmpliacionTextarea pt-1 ps-3"
                                            value={docu.otros}
                                            onChange={(e) =>
                                                setDocu((prevState) => ({
                                                    ...prevState,
                                                    otros: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </section>
                            </section>
                        </section>
                    </div>
                    :
                    <div className="center pt-5"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div>}
                {cargando ?
                    <section className="rowCronologia" style={{ opacity: opacity === 1 ? 0.25 : 1 }}>
                        <div className="cronologiaStroContainer pe-4 ps-4">
                            <h2 className="tituloCronologiaStro ps-3">CRONOLOGIA DEL STRO</h2>
                            {denuncia?.cronologia.map((m) => (
                                <MensajesCronologiaCarga mensaje={m} />
                            ))}
                            <div className="d-flex mt-5">
                                <textarea className="mensajeCronoliaStro ps-3 pt-1" value={mensaje.obs}
                                    onChange={(e) =>
                                        setMensaje((prevState) => ({
                                            ...prevState,
                                            obs: e.target.value,
                                        }))} />
                                <div className="sendContainer">
                                    <div className="sendMessageBtnContainer center">
                                        <button className="btnNO text-white" onClick={(e) => comentarioUpdate(e)} disabled={mensaje.motivo === null ? true : false}>
                                            <i class="bi bi-send"></i>
                                        </button>
                                    </div>
                                    <div className="sendMessageUserContainer mt-2">
                                        <Select
                                            styles={colourStyles}
                                            options={motivoMensajeInput} className='w-100'
                                            placeholder=''
                                            onChange={(newValue) => setMensaje((prevState) => ({
                                                ...prevState,
                                                motivo: newValue.value,
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    : <></>}
            </main>
            {carga2 ? <PDFDownloadLink document={<PDF sicobe={sicobe} pagosAnteriores={pagosAnteriores1} cobert={cobert} denunciaParaSicobe={denunciaParaSicobe} />} fileName={`sicobeStro${denunciaParaSicobe}.pdf`}>
                {({ loading, url, error, blob }) => <div className='d-none'><button id="botonSicobe">Descargar Sicobe</button></div>}
            </PDFDownloadLink> : null}
        </>
    );
}


