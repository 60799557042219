import { useEffect, useState } from "react";
import "../../pages/cargaTabla.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { checkContext } from "../../context/Checka";
import axios from "axios";
export default function ComponenteValoresContractuales({ denuncia, estados }) {
    const link = { textDecoration: "none" };
    const { checka, setChecka } = useContext(checkContext);
    const [check, setChecked] = useState(false);
    function handleChange(e) {
        setChecked(e.target.checked);
        if (e.target.checked) {
            if (!checka.includes(denuncia.id)) {
                setChecka((prevChecka) => [...prevChecka, denuncia.id]);
            }
        } else {
            if (checka.includes(denuncia.id)) {
                setChecka((prevChecka) =>
                    prevChecka.filter((item) => item !== denuncia.id)
                );
            }
        }
    }
    function formatDate(dateString) {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`;
    }
    const handleClick = (event, id) => {
        event.preventDefault();
        if (event.ctrlKey || event.metaKey) {
            window.open(`/#/${id}`, "_blank");
        } else {
            event.preventDefault();
            window.location.href = event.currentTarget.href;
        }
    };
    const optionsPrioridad = [
        { label: "", value: 0 },
        { label: "BAJA", value: 1 },
        { label: "MEDIA", value: 2 },
        { label: "ALTA", value: 3 },
    ];
    const prioridadSeleccionado = optionsPrioridad.find(
        (p) => p.value === Number(denuncia.prioridad)
    );

    return (
        <>
            <tr className="" style={{ height: "50px" }}>
                <th className="ps-0 pe-0 align-middle">
                    <div className="d-flex">
                        <button
                            className="text-center"
                            style={{ width: "25px", height: "25px" }}
                            data-bs-toggle="modal"
                            data-bs-target={`#modal-${denuncia?.id}`}
                        >
                            +
                        </button>
                        <h4 className="tablaValoresContractualesBody m-0 ms-1">
                            {denuncia?.nroStro?.toString().substring(3)}
                        </h4>
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <h4 className="m-0 tablaValoresContractualesBody">
                        {denuncia?.entryId}
                    </h4>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <h4 className="m-0 tablaValoresContractualesBody">{denuncia?.pza}</h4>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="m-0 tablaValoresContractualesBody">
                        {denuncia?.dom}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <h4 className="m-0 tablaValoresContractualesBody">
                        {formatDate(denuncia?.fechaDenuncia)}
                    </h4>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <h4 className="m-0 tablaValoresContractualesBody">
                        {formatDate(denuncia?.fechaStro)}
                    </h4>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <h4 className="m-0 tablaValoresContractualesBody">
                        {denuncia?.recepcionRecChoques
                            ? formatDate(denuncia?.recepcionRecChoques)
                            : null}
                    </h4>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <h4 className="m-0 tablaValoresContractualesBody">
                        {denuncia?.fechaVencimiento
                            ? formatDate(denuncia?.fechaVencimiento)
                            : null}
                    </h4>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <h4 className="m-0 tablaValoresContractualesBody">
                        {prioridadSeleccionado.label}
                    </h4>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        <div className="d-flex align-items-center">
                            <div
                                className={
                                    denuncia.empleadoAsignado ? "circleVioleta me-1 center" : ""
                                }
                            ></div>
                            {denuncia.empleadoAsignado}
                        </div>
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        <div className="d-flex align-items-center">
                            <div
                                className={
                                    denuncia.estadoNuevo === 203
                                        ? "circleRojo me-1"
                                        : denuncia.estadoNuevo === 200
                                            ? "circleGris me-1"
                                            : "circleVerde me-1"
                                }
                            ></div>
                            {
                                estados.find((e) => e.id === Number(denuncia.estadoNuevo))
                                    ?.abreviacion
                            }
                        </div>
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.catStro}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.ausente}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.contadorAusente}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.videollamada}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.fRecInsp}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.docInfInv}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.fRecInv}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.nombreProveedor}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.montoReconsideracion}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.tipoArchivo}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.fIngresoArchivo}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.tipoTerminado}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.fIngresoTerminado}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.fRecBajaGestor}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.recBajaGestor}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.incompletaBaja}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.fAsignadoPorJJ}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.porResolucion}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.porFechaPago}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.observacion}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.ausenteEII}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.contadorAusenteEII}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.videollamadaEII}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.observacionEII}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.observacionEFP}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.coordinadaEII}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.fObservacionesDoc}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.observacionesDoc}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <div className="tablaValoresContractualesBody m-0">
                        {denuncia?.valores?.fechaEsperaAceptacion}
                    </div>
                </th>
                <th className="ps-0 pe-0 align-middle">
                    <Link
                        to={`/${denuncia.id}`}
                        onClick={(event) => handleClick(event, denuncia.id)}
                        className="tablaValoresContractualesBody text-primary m-0"
                    >
                        INGRESAR
                    </Link>
                </th>
            </tr>
            <div className="modal fade" id={`modal-${denuncia?.id}`} tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header" style={{ display: 'block' }}>
                            <div className="w-100">
                                <div className=" d-flex">
                                    <div className="item-bloque w-100">
                                        <strong>STRO:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {denuncia?.nroStro?.toString().substring(3)}
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>DEN:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {denuncia?.entryId}
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>PZA:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {denuncia?.pza}
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>DOM:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {denuncia?.dom}
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>ESTADO:</strong>
                                        <div className="ampliacionesModalTexto">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className={
                                                        denuncia.estadoNuevo === 203
                                                            ? "circleRojo me-1"
                                                            : denuncia.estadoNuevo === 200
                                                                ? "circleGris me-1"
                                                                : "circleVerde me-1"
                                                    }
                                                ></div>
                                                {
                                                    estados.find(
                                                        (e) => e.id === Number(denuncia.estadoNuevo)
                                                    )?.abreviacion
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>TIPO STRO:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {denuncia?.catStro}
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                            </div>
                            <div className="w-100 mt-3">
                                <div className="d-flex">
                                    <div className="item-bloque w-100">
                                        <strong>F.RECEP:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {formatDate(denuncia?.fechaDenuncia)}
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>F.STRO:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {formatDate(denuncia?.fechaStro)}
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>F.REC RECIB:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {denuncia?.recepcionRecChoques
                                                ? formatDate(denuncia?.recepcionRecChoques)
                                                : null}
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>F.VTO:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {denuncia?.fechaVencimiento
                                                ? formatDate(denuncia?.fechaVencimiento)
                                                : null}
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>PRIORIDAD:</strong>
                                        <div className="ampliacionesModalTexto">
                                            {prioridadSeleccionado.label}
                                        </div>
                                    </div>
                                    <div className="item-bloque w-100">
                                        <strong>ASIGNADO:</strong>
                                        <div className="ampliacionesModalTexto">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className={
                                                        denuncia.empleadoAsignado
                                                            ? "circleVioleta me-1 center"
                                                            : ""
                                                    }
                                                ></div>
                                                {denuncia.empleadoAsignado}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {denuncia?.tipoStro === 'ROBO PARCIAL' || denuncia?.tipoStro === 'ROBO PARCIAL INSP' ?
                            <div className="modal-body">
                                <div className="row">
                                    <h5>ESPERA DOCUMENTACIÓN</h5>
                                    <div className="d-flex mt-2">
                                        <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Observaciones Doc:</strong>
                                        <input type='text' className='form-control form-control-sm rounded-pill' value={denuncia?.valores?.observacionesDoc} />
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <h5>ESPERA INFORME INVESTIGACION</h5>
                                    <div className="row mt-2">
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Doc Informe Investigacion:</strong>
                                            <input type='text' className='form-control form-control-sm rounded-pill' value={denuncia?.valores?.docInfInv} />
                                        </div>
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Fecha Recepcion Investigacion:</strong>
                                            <input type='date' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.fRecInv} />
                                        </div>
                                    </div>
                                    <div className="mt-2 row">
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Ausente:</strong>
                                            <input type='date' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.ausenteEII} />
                                        </div>
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Contador Ausente:</strong>
                                            <input type='number' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.contadorAusenteEII} />
                                        </div>
                                    </div>
                                    <div className="mt-2 row">
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Videollamada:</strong>
                                            <input type='checkbox' className='form-check-input rounded-pill' value={denuncia?.valores?.videollamadaEII} />
                                        </div>
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Observacion:</strong>
                                            <input type='text' className='form-control form-control-sm rounded-pill' value={denuncia?.valores?.observacionEII} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <h5>ESPERA FACTURA PROVEEDOR</h5>
                                    <div className="row">
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Nombre Proveedor:</strong>
                                            <input type='text' className='form-control form-control-sm rounded-pill' value={denuncia?.valores?.nombreProveedor} />
                                        </div>
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Observacion:</strong>
                                            <input type='text' className='form-control form-control-sm rounded-pill' value={denuncia?.valores?.observacionEFP} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <h5>ARCHIVO-CTR</h5>
                                    <div className="row">
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Tipo Archivo:</strong>
                                            <select className="form-select form-select-sm rounded-pill" value={denuncia?.valores?.tipoArchivo}>
                                                {/* Verificar si el campo es null o vacío */}
                                                {(denuncia?.valores?.tipoArchivo === null || denuncia?.valores?.tipoArchivo === "") && (
                                                    <option value="" disabled>Selecciona una opción</option>
                                                )}
                                                <option value="ARCHIVO">ARCHIVO</option>
                                                <option value="ARCHIVO ESPERA ACEPTACIÓN">ARCHIVO ESPERA ACEPTACIÓN</option>
                                                <option value="ARCHIVO FACTURA PROVEEDOR">ARCHIVO FACTURA PROVEEDOR</option>
                                            </select>
                                        </div>
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Fecha Ingreso Archivo:</strong>
                                            <input type='date' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.fIngresoArchivo} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <h5>TERMINADO-CTR</h5>
                                    <div className="row">
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Tipo Terminado</strong>
                                            <select className="form-select form-select-sm rounded-pill" value={denuncia?.valores?.tipoTerminado}>
                                                {/* Verificar si el campo es null o vacío */}
                                                {(denuncia?.valores?.tipoTerminado === null || denuncia?.valores?.tipoTerminado === "") && (
                                                    <option value="" disabled>Selecciona una opción</option>
                                                )}
                                                <option value="TERMINADO DESCUENTO A MORÓN">TERMINADO DESCUENTO A MORÓN</option>
                                                <option value="TERMINADO A TESORERÍA">TERMINADO A TESORERÍA</option>
                                                <option value="TERMINADO RECHAZADO">TERMINADO RECHAZADO</option>
                                                <option value="TERMINADO TRANSFERENCIA SALTA">TERMINADO TRANSFERENCIA SALTA</option>
                                                <option value="DESISTIDO">DESISTIDO</option>

                                            </select>
                                        </div>
                                        <div className="col-6 d-flex">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Fecha Ingreso Terminado</strong>
                                            <input type='date' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.fIngresoTerminado} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            (denuncia?.tipoStro === 'ROBO TOTAL' || denuncia?.tipoStro === 'ROBO APARECIDO IND' || denuncia?.tipoStro === 'ROBO APARECIDO' ?
                                <div className="modal-body">
                                    <div className="row">
                                        <h5>ESPERA DOCUMENTACIÓN</h5>
                                        <div className="d-flex mt-2">
                                            <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Observaciones Doc:</strong>
                                            <input type='text' className='form-control form-control-sm rounded-pill' value={denuncia?.valores?.observacionesDoc} />
                                        </div>
                                    </div>
                                    <hr />
                                    {denuncia?.tipoStro === 'ROBO APARECIDO IND' || denuncia?.tipoStro === 'ROBO APARECIDO' ?
                                        <div className="row">
                                            <h5>ESPERA INFORME INSPECCION</h5>
                                            <div className="row">
                                                <div className="col-6 d-flex">
                                                    <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>AUSENTE:</strong>
                                                    <input type='date' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.ausente} />
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>CONTADOR AUSENTE:</strong>
                                                    <input type='number' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.contadorAusente} />
                                                </div>
                                            </div>
                                            <div className="mt-3 row">
                                                <div className="col-6 d-flex">
                                                    <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>VIDEOLLAMADA:</strong>
                                                    <input type='checkbox' className='form-check-input rounded-pill' value={denuncia?.valores?.videollamada} />
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>FECHA RECEPCION INSPECCION:</strong>
                                                    <input type='date' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.fRecInsp} />
                                                </div>
                                            </div>
                                            <div className="mt-3 row">
                                                <div className="col-6 d-flex">
                                                    <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Observacion:</strong>
                                                    <input type='text' className='form-control form-control-sm rounded-pill' value={denuncia?.valores?.observacion} />
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>COORDINADA:</strong>
                                                    <input type='date' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.coordinadaEII} />
                                                </div>
                                            </div>
                                        </div> : <></>}
                                    {denuncia?.tipoStro === 'ROBO APARECIDO IND' || denuncia?.tipoStro === 'ROBO APARECIDO' ? <hr></hr> : <></>}
                                    <div className="row">
                                        <h5>ESPERA INFORME INVESTIGACION</h5>
                                        <div className="row">
                                            <div className="col-6 d-flex">
                                                <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Doc Informe Investigacion:</strong>
                                                <input type='text' className='form-control form-control-sm rounded-pill' value={denuncia?.valores?.docInfInv} />
                                            </div>
                                            <div className="col-6 d-flex">
                                                <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Fecha Recepcion Investigacion:</strong>
                                                <input type='date' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.fRecInv} />
                                            </div>
                                        </div>
                                        <div className="mt-3 row">
                                            <div className="col-6 d-flex">
                                                <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Ausente:</strong>
                                                <input type='date' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.ausenteEII} />
                                            </div>
                                            <div className="col-6 d-flex">
                                                <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Contador Ausente:</strong>
                                                <input type='number' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.contadorAusenteEII} />
                                            </div>
                                        </div>
                                        <div className="mt-3 row">
                                            <div className="col-6 d-flex">
                                                <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Videollamada:</strong>
                                                <input type='checkbox' className='form-control form-control-sm rounded-pill' style={{ maxWidth: '140px' }} value={denuncia?.valores?.videollamadaEII} />
                                            </div>
                                            <div className="col-6 d-flex">
                                                <strong style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Observacion:</strong>
                                                <input type='text' className='form-control form-control-sm rounded-pill' value={denuncia?.valores?.observacionEII} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <h5>BAJA A GESTOR</h5>
                                        <div className="row">
                                            <div className="col-6 item-bloque">
                                                <strong>Fecha Recepcion Baja Gestor:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.fRecBajaGestor}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <h5>BAJA DEVUELTA X GESTOR</h5>
                                        <div className="row">
                                            <div className="col-6 item-bloque">
                                                <strong>Recepcion Baja Gestion:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.recBajaGestor}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <h5>BAJA DEVUELTA X GESTOR</h5>
                                        <div className="row">
                                            <div className="col-6 item-bloque">
                                                <strong>Recepcion Baja Gestion:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.recBajaGestor}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <h5>ESTADO BAJA</h5>
                                        <div className="row">
                                            <div className="col-6 item-bloque">
                                                <strong>Incompleta Baja:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.incompletaBaja}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <h5>EN PROCESO JJ</h5>
                                        <div className="row">
                                            <div className="col-6 item-bloque">
                                                <strong>Fecha Asignada por JJ:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.fAsignadoPorJJ}
                                                </div>
                                            </div>
                                            <div className="col-6 item-bloque">
                                                <strong>Por Resolucion:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.porResolucion}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 item-bloque">
                                                <strong>Por Fecha Pago:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.porFechaPago}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <h5>EN ESPERA ACEPTACION</h5>
                                        <div className="row">
                                            <div className="col-6 item-bloque">
                                                <strong>Fecha Espera Aceptacion:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.fechaEsperaAceptacion}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <h5>TERMINADO-CTR</h5>
                                        <div className="row">
                                            <div className="col-6 item-bloque">
                                                <strong>Tipo Terminado:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.tipoTerminado}
                                                </div>
                                            </div>
                                            <div className="col-6 item-bloque">
                                                <strong>Fecha Ingreso Terminado:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.fIngresoTerminado}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                : (denuncia.tipoStro === 'INCENDIO PARCIAL' || denuncia?.tipoStro === 'INCENDIO TOTAL' || denuncia?.tipoStro === 'DAÑO PARCIAL' || denuncia?.tipoStro === 'DAÑO TOTAL' ?
                                    <div className="modal-body">
                                        <div className="row">
                                            <h5>ESPERA DOCUMENTACIÓN</h5>
                                            <div className="col-6 item-bloque">
                                                <strong>Observaciones Doc:</strong>
                                                <div className="ampliacionesModalTexto">
                                                    {denuncia?.valores?.observacionesDoc}
                                                </div>
                                            </div>

                                        </div>
                                        <hr />
                                        <div className="row">
                                            <h5>ESPERA INFORME INSPECCION</h5>
                                            <div className="row">
                                                <div className="col-6 item-bloque">
                                                    <strong>AUSENTE:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.ausente}
                                                    </div>
                                                </div>
                                                <div className="col-6 item-bloque">
                                                    <strong>CONTADOR AUSENTE:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.contadorAusente}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3 row">
                                                <div className="col-6 item-bloque">
                                                    <strong>VIDEOLLAMADA:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.videollamada}
                                                    </div>
                                                </div>
                                                <div className="col-6 item-bloque">
                                                    <strong>FECHA RECEPCION INSPECCION:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.fRecInsp}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3 row">
                                                <div className="col-6 item-bloque">
                                                    <strong>Observacion:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.observacion}
                                                    </div>
                                                </div>
                                                <div className="col-6 item-bloque">
                                                    <strong>COORDINADA:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.coordinadaEII}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <h5>ESPERA INFORME INVESTIGACION</h5>
                                            <div className="row">
                                                <div className="col-6 item-bloque">
                                                    <strong>Doc Informe Investigacion:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.docInfInv}
                                                    </div>
                                                </div>
                                                <div className="col-6 item-bloque">
                                                    <strong>Fecha Recepcion Investigacion:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.fRecInv}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3 row">
                                                <div className="col-6 item-bloque">
                                                    <strong>Ausente:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.ausenteEII}
                                                    </div>
                                                </div>
                                                <div className="col-6 item-bloque">
                                                    <strong>Contador Ausente:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.contadorAusenteEII}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3 row">
                                                <div className="col-6 item-bloque">
                                                    <strong>Videollamada:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.videollamadaEII}
                                                    </div>
                                                </div>
                                                <div className="col-6 item-bloque">
                                                    <strong>Observacion:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.observacionEII}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <h5>ESPERA FACTURA PROVEEDOR</h5>
                                            <div className="row">
                                                <div className="col-6 item-bloque">
                                                    <strong>Nombre Proveedor:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.nombreProveedor}
                                                    </div>
                                                </div>
                                                <div className="col-6 item-bloque">
                                                    <strong>Observacion:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.observacionEFP}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <h5>ARCHIVO-CTR</h5>
                                            <div className="row">
                                                <div className="col-6 item-bloque">
                                                    <strong>Tipo Archivo:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.tipoArchivo}
                                                    </div>
                                                </div>
                                                <div className="col-6 item-bloque">
                                                    <strong>Fecha Ingreso Archivo:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.fIngresoArchivo}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <h5>TERMINADO-CTR</h5>
                                            <div className="row">
                                                <div className="col-6 item-bloque">
                                                    <strong>Tipo Terminado:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.tipoTerminado}
                                                    </div>
                                                </div>
                                                <div className="col-6 item-bloque">
                                                    <strong>Fecha Ingreso Terminado:</strong>
                                                    <div className="ampliacionesModalTexto">
                                                        {denuncia?.valores?.fIngresoTerminado}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                ))}
                        <div className="modal-footer d-flex">
                            <Link
                                to={`/${denuncia.id}`}
                                onClick={(event) => handleClick(event, denuncia.id)}
                                className="btn btn-primary"
                            >
                                INGRESAR
                            </Link>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


